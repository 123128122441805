<template>
  <main id="content" role="main" class="overflow-hidden">
    <!-- ========== Hero Section ========== -->
    <div class="position-relative z-index-2 bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/svg/components/hk-bg-light-2.jpg') + ')'}">
      <div class="container space-3 mt-md-3 mb-md-3 position-relative">
        <!-- Title -->
        <div class="w-100 w-md-75 w-lg-65 mx-auto text-center">
          <!-- <div>
            <img class="img-fluid mb-6" src="@/assets/img/apidays-2023/images/apidays-2023-logo.png" style="max-width:18rem">
          </div> -->
          <span class="d-block small font-weight-bold text-cap mb-2">Apidays Connect Hong Kong 2023</span>
          <h1
            class="mb-4 display-4"
            style="font-weight:800;"
          >The API Landscape of Hong Kong</h1>
          <p class="lead mb-4">The Biggest API Ecosystem Conference in Hong Kong</p>
          <p class="text-body mb-1">Aug 30, 2023 | Physically at Conference Halls 4-7, 2/F, Lakeside 2, Phase 2, Hong Kong Science Park</p>
          <p class="text-body">Aug 31, 2023 | Virtually via online platform</p>
          <!-- Register Button -->
          <!-- <div class="text-center mt-9">
            <a
              class="js-go-to btn btn-wide btn-primary transition-3d-hover"
              href="javascript:;"
              data-hs-go-to-options="{
                  &quot;targetSelector&quot;: &quot;#registration&quot;,
                  &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                  &quot;offsetTop&quot;: 0,
                  &quot;position&quot;: null,
                  &quot;animationIn&quot;: false,
                  &quot;animationOut&quot;: false
                  }"
            >Register Now
            </a>
          </div> -->
        </div>
      </div>
      <!-- SVG Background -->
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
        </svg>
      </figure>
      <!-- End SVG Background -->
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== 2023 Highlights ========== -->
    <div class="container position-relative z-index-2 mt-n11 mb-7">
      <div class="position-relative bg-navy rounded px-2">
        <div class="container space-1 space-md-2 position-relative z-index-2">
          <!-- Title -->
          <div class="text-center mb-5">
            <img class="img-fluid max-w-11rem mb-3" src="@/assets/svg/illustrations/popper.svg">
            <h2 class="text-white">Apidays Connect Hong Kong 2023:<br>A Remarkable Success!</h2>
          </div>

          <!-- Content -->
          <p class="text-white text-center w-lg-80 mx-auto mb-7">A Big thank you to our Sponsors, Supporting Organizations, and Participants for making this event a resounding success! Let's rewind and relive the best moments of the event together.</p>

          <div class="w-lg-80 mx-auto">
            <div class="card bg-primary h-100 overflow-hidden p-5">
              <div class="w-75">
                <h3 class="text-white">Apidays 2023 playback videos are now available!</h3>
                <p class="text-white">Interested in watching the full replay of our Day 1 & Day 2 event?<br>Register and start watching today!</p>
                <div>
                  <a href="javascript:;" data-toggle="modal" data-target="#oahResourcesModal" class="btn btn-sm btn-light">Watch Now</a>
                </div>
              </div>
              <div class="position-absolute right-0 bottom-0 w-35 mb-n3 mr-n4">
                <img src="@/assets/svg/illustrations/event-resources.svg" class="img-fluid">
              </div>
            </div>
          </div>

          <figure class="max-w-15rem w-100 position-absolute bottom-0 right-0">
            <div class="mb-n7 mr-n7">
              <img class="img-fluid" src="@/assets/svg/components/dots-4.svg">
            </div>
          </figure>
        </div>
      </div>
    </div>
    <!-- Photo Gallery -->
    <div class="container space-2">
      <!-- Day 1 -->
      <div class="mb-7">
        <h3 class="text-center mb-4">Day 1 Physical Conference Highlights</h3>
        <div id="fancyboxGallery1" class="row mx-auto">
          <div v-for="item in gallery_list" :key="item.image_url" class="col-12 px-2 transition-3d-hover" :class="item.class">
            <a
              class="js-fancybox media-viewer"
              href="javascript:;"
              data-hs-fancybox-options="{
                            &quot;selector&quot;: &quot;#fancyboxGallery1 .js-fancybox&quot;,
                            &quot;speed&quot;: 700,
                            &quot;loop&quot;: true
                        }"
              :data-src="require('@/assets/img/apidays-2023/images/day-1/' + item.image_url)"
              data-caption="Apidays Connect Hong Kong 2023 - Day 1 Physical Conference"
            >
              <div class="img-fluid rounded h-250rem bg-img-hero shadow-soft" :style="{'background-image': 'url(' + require('@/assets/img/apidays-2023/images/day-1/' + item.image_url) + ')'}" data-aos="fade-up" :data-aos-delay="item.data_aos_delay" />
            </a>
          </div>
        </div>
      </div>

      <hr class="mb-7">

      <!-- Day 2 -->
      <div class="">
        <h3 class="text-center mb-3">Day 2 Virtual Conference Highlights</h3>
        <div id="fancyboxGallery2" class="row mx-auto">
          <div v-for="item in gallery_list_2" :key="item.image_url" class="col-12 px-2 transition-3d-hover" :class="item.class">
            <a
              class="js-fancybox media-viewer"
              href="javascript:;"
              data-hs-fancybox-options="{
                            &quot;selector&quot;: &quot;#fancyboxGallery2 .js-fancybox&quot;,
                            &quot;speed&quot;: 700,
                            &quot;loop&quot;: true
                        }"
              :data-src="require('@/assets/img/apidays-2023/images/day-2/' + item.image_url)"
              data-caption="Apidays Connect Hong Kong 2023 - Day 2 Virtual Conference"
            >
              <div class="img-fluid rounded h-250rem bg-img-hero shadow-soft" :style="{'background-image': 'url(' + require('@/assets/img/apidays-2023/images/day-2/' + item.image_url) + ')'}" data-aos="fade-up" :data-aos-delay="item.data_aos_delay" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End 2023 Highlights ========== -->

    <!-- ========== About Section========== -->
    <div class="space-2" style="background-color:#f5f9fc;">
      <div class="container">
        <div class="row justify-content-lg-between mb-4 mb-lg-11">
          <div class="col-lg-6 order-lg-2">
            <div class="bg-img-hero h-100 min-h-380rem" :style="{'background-image': 'url(' + require('@/assets/img/events/apidays-live-hongkong/2021/images/hongkong.jpg') + ')'}" />
          </div>
          <div class="col-lg-6 order-lg-1">
            <div class="pt-8 pt-lg-0">
              <div class="mb-5 mb-md-7">
                <span class="d-block small font-weight-bold text-cap mb-2">About the Event</span>
                <h2>The API Landscape of Hong Kong</h2>
                <p>The API landscape in Hong Kong is evolving rapidly, with APIs playing a crucial role in the platform economy and driving digital transformation across industries. APIs offer a standardized way to share data and functionality between applications and systems, enabling businesses to become more efficient, innovative, and customer-centric.</p>
                <p>Apidays Connect Hong Kong 2023 provides an opportunity for attendees to learn about the latest API applications, network with industry experts, and gain insights into the future of Hong Kong's tech ecosystem, data-driven technologies, fintech, generative AI, the financial industry, and beyond. Discover how APIs can help your business thrive in this dynamic and rapidly evolving landscape.</p>
              </div>
            </div>
          </div>
        </div>
        <ul class="step step-md step-centered space-bottom-2">
          <li class="step-item">
            <div class="step-content-wrapper">
              <span class="step-icon step-icon-soft-primary"><i class="fas fa-phone-laptop" /></span>
              <div class="step-content">
                <h4>Tech Ecosystem & API</h4>
                <p class="text-body">Discover how APIs are driving innovation in Hong Kong's tech industry.</p>
              </div>
            </div>
          </li>

          <li class="step-item">
            <div class="step-content-wrapper">
              <span class="step-icon step-icon-soft-primary"><i class="far fa-database" /></span>
              <div class="step-content">
                <h4>Data & API</h4>
                <p class="text-body">Learn how APIs transform data management and analysis for businesses.</p>
              </div>
            </div>
          </li>

          <li class="step-item">
            <div class="step-content-wrapper">
              <span class="step-icon step-icon-soft-primary"><i class="far fa-credit-card" /></span>
              <div class="step-content">
                <h4>Fintech & API</h4>
                <p class="text-body">Explore the benefits, trends and impacts of using APIs in the financial industry.</p>
              </div>
            </div>
          </li>

          <li class="step-item">
            <div class="step-content-wrapper">
              <span class="step-icon step-icon-soft-primary"><i class="far fa-robot" /></span>
              <div class="step-content">
                <h4>Generative AI & API</h4>
                <p class="text-body">Learn about the impact of Generative AI on APIs and software development.</p>
              </div>
            </div>
          </li>

          <li class="step-item">
            <div class="step-content-wrapper">
              <span class="step-icon step-icon-soft-primary"><i class="far fa-donate" /></span>
              <div class="step-content">
                <h4>Fund Raising & API</h4>
                <p class="text-body">Explore the role of APIs in driving the growth in venture capital investments.</p>
              </div>
            </div>
          </li>
        </ul>
        <!-- Date -->
        <div class="row space-bottom-1">
          <div class="col-12 col-md-6 mb-5 mb-md-0">
            <div class="card h-100">
              <div class="card-header text-center py-3" style="background-color: #0163a1">
                <h3 class="mb-0 text-white">The API Landscape</h3>
                <h4 class="mb-0 text-white">Day 1 - Physical Exclusives in Hong Kong Science Park</h4>
              </div>
              <div class="card-body text-body">
                <p class="text-body mb-4">
                  Don't miss out on the excitement of Day 1! The exclusive content is only available to those who attend in person!
                </p>
                <div class="media mb-2">
                  <span class="icon icon-xs icon-primary icon-circle mr-3">
                    <i class="fas fa-calendar-alt" />
                  </span>
                  <div class="media-body text-body">
                    Aug 30, 2023 (09:30-18:00 HKT)
                  </div>
                </div>
                <div class="media mb-2">
                  <span class="icon icon-xs icon-primary icon-circle mr-3">
                    <i class="fas fa-map-marker-alt" />
                  </span>
                  <div class="media-body text-body">
                    <a href="https://www.google.com/maps/place/Lakeside+2,+10+Science+Park+W+Ave,+Science+Park/@22.4267244,114.210722,17z/data=!3m1!4b1!4m6!3m5!1s0x34040897409caedf:0xe759bb6a4a3dc2cc!8m2!3d22.4267244!4d114.210722!16s%2Fg%2F12hs1qdk_?entry=ttu" target="_blank">Conference Halls 4-7, 2/F, Lakeside 2, Phase 2, Hong Kong Science Park <i class="fas fa-external-link-alt ml-2" /></a>
                  </div>
                </div>
                <div class="media mb-2">
                  <span class="icon icon-xs icon-primary icon-circle mr-3">
                    <i class="fas fa-dollar-sign" />
                  </span>
                  <div class="media-body text-body">
                    Physical Event Pass with Special-Designed NFT Gift
                  </div>
                </div>
              </div>
            <!-- <div class="card-footer border-0">
              <a class="btn btn-wide btn-primary" href="https://app.moongate.id/events/apidays2023?utm_source=website&utm_medium=website" target="_blank">Get Ticket Now - Limited Seat</a>
            </div> -->
            </div>
          </div>
          <div class="col-12 col-md-6 mb-0 mb-md-0">
            <div class="card h-100">
              <div class="card-header text-center py-3" style="background-color: #0163a1">
                <h3 class="mb-0 text-white">The API Master Classes</h3>
                <h4 class="mb-0 text-white">Day 2 - Virtual Conference Online</h4>
              </div>
              <div class="card-body">
                <p class="text-body mb-4">
                  Step into our virtual booth on Day 2 and access a diverse range of API Master Classes - for FREE!
                </p>
                <div class="media mb-2">
                  <span class="icon icon-xs icon-primary icon-circle mr-3" style="background-color: #0163a1 !important">
                    <i class="fas fa-calendar-alt" />
                  </span>
                  <div class="media-body text-body">
                    Aug 31, 2023 (10:00-18:00 HKT)
                  </div>
                </div>
                <div class="media mb-2">
                  <span class="icon icon-xs icon-primary icon-circle mr-3" style="background-color: #0163a1 !important">
                    <i class="fas fa-map-marker-alt" />
                  </span>
                  <div class="media-body text-body">
                    Online platform
                  </div>
                </div>
                <div class="media mb-2">
                  <span class="icon icon-xs icon-primary icon-circle mr-3" style="background-color: #0163a1 !important">
                    <i class="fas fa-dollar-sign" />
                  </span>
                  <div class="media-body text-body">
                    Free Virtual Event Pass with NFT Gift
                  </div>
                </div>
              </div>
            <!-- <div class="card-footer border-0">
              <a class="btn btn-wide btn-primary border-0" style="background-color: #0163a1 !important" href="https://app.moongate.id/events/apidays2023?utm_source=website&utm_medium=website" target="_blank">Get Free Ticket for Day 2 Now</a>
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End About Section========== -->

    <!-- ========== Registration Section========== -->
    <!-- <div id="registration" class="space-2 space-lg-3" style="background-color:#f5f9fc;">
      <div class="container">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-6">
          <h2>Calling for Speakers & Sponsors!</h2>
          <p>Excited to be a part of our event? We're accepting applications for various roles! Simply fill out the forms below and let us know how you can contribute.</p>
        </div>
        <div class="row">
          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://forms.office.com/Pages/ResponsePage.aspx?id=LLfXUlCtHkGdT8oFy6wChyaOGdzghXZHl7uXgaoQPGZUQ1I2Qkw5TFZTSzJEVFZKUk84QUFCTDFDQy4u" target="_blank">
              <div class="card-body pt-6">
                <img class="img-fluid max-w-8rem mb-3" src="@/assets/svg/icons/icon-7.svg">
                <h3>Speakers</h3>
                <p class="text-body">Apply now to become a featured speaker and share your expertise.</p>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Apply Now <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>

          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://forms.office.com/Pages/ResponsePage.aspx?id=LLfXUlCtHkGdT8oFy6wChyaOGdzghXZHl7uXgaoQPGZURUgxWE5XQVlQS0hHU0FNTTJFM1lXSE0xVi4u" target="_blank">
              <div class="card-body pt-6">
                <img class="img-fluid max-w-8rem mb-3" src="@/assets/svg/icons/icon-30.svg">
                <h3>Sponsors</h3>
                <p class="text-body">Partner with us as a sponsor and gain prominent visibility at our event.</p>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>

          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="javascript:;" data-toggle="modal" data-target="#registerModal" target="_blank">
              <div class="card-body pt-6">
                <img class="img-fluid max-w-8rem mb-3" src="@/assets/svg/icons/icon-48.svg">
                <h3>Audience</h3>
                <p class="text-body">Join us as participants and learn from the best API experts.</p>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Register Now <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Registration Section========== -->

    <!-- ========== Organiser Section ========== -->
    <div style="background-color:#f5f9fc;">
      <OrganiserBar :list="organiser_list" />
    </div>
    <!-- ========== End Organiser Section ========== -->

    <!-- ========== Agenda Section ========== -->
    <div class="container space-2">
      <!-- Title -->
      <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">Schedule Details</span>
        <h2>2023 Agenda</h2>
      </div>
      <!-- End Title -->

      <div id="apidays2023agenda" class="row">
        <div class="col-lg-5 mb-4 mb-lg-0">
          <a
            class="js-fancybox media-viewer transition-3d-hover"
            href="javascript:;"
            data-hs-fancybox-options="{
              &quot;selector&quot;: &quot;#apidays2023agenda .js-fancybox&quot;,
              &quot;speed&quot;: 700
            }"
            :data-src="require('@/assets/img/apidays-2023/images/apidays-hongkong-2023-agenda-0830.png')"
            data-caption="Apidays Connect Hong Kong 2023 Agenda - Day 1 (August 30, 2023)"
          >
            <img class="img-fluid rounded shadow" src="@/assets/img/apidays-2023/images/apidays-hongkong-2023-agenda-0830.png" alt="Apidays Connect Hong Kong 2023 Agenda - Day 1 (August 30, 2023)">
          </a>
        </div>
        <div class="col-lg-7 mb-0">
          <a
            class="js-fancybox media-viewer"
            href="javascript:;"
            data-hs-fancybox-options="{
              &quot;selector&quot;: &quot;#apidays2023agenda .js-fancybox&quot;,
              &quot;speed&quot;: 700
            }"
            :data-src="require('@/assets/img/apidays-2023/images/apidays-hongkong-2023-agenda-0831.png')"
            data-caption="Apidays Connect Hong Kong 2023 Agenda - Day 2 (August 31, 2023)"
          >
            <img class="img-fluid rounded shadow" src="@/assets/img/apidays-2023/images/apidays-hongkong-2023-agenda-0831.png" alt="Apidays Connect Hong Kong 2023 Agenda - Day 2 (August 31, 2023)">
          </a>
        </div>
      </div>

      <!-- <div class="text-center mt-6">
        <p class="small">* Day 2 agenda is coming soon!</p>
      </div> -->

    </div>
    <!-- ========== End Agenda Section ========== -->

    <!-- ========== Speakers Section ========== -->
    <div id="speakers" class="space-2" style="background-color:#f5f9fc;">
      <div class="container">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5 mb-md-6">
          <span class="d-block small font-weight-bold text-cap mb-2">Our Speakers</span>
          <h2 class="mb-5">2023 Speaker Lineup</h2>
          <h3>Day 1 Speakers</h3>
        </div>

        <!-- Filters -->
        <!---->
        <input v-for="list in speaker_list" :id="list.group" :key="list.group" v-model="selected_group" type="radio" name="categories" :value="list.group">

        <!--
        <ol class="filters-btn" style="margin-left: 1rem; overflow: auto; white-space: nowrap; -webkit-overflow-scrolling: touch;">
          <li v-for="list in speaker_list" :key="list.group">
            <label class="btn btn-sm btn-soft-primary btn-pill py-2 px-3 m-1" :class="{'selectedGroup': selected_group === list.group}" :for="list.group">{{ list.title }}</label>
          </li>
        </ol>
        -->
        <!-- End Filters -->

        <span v-for="list in speaker_list" :key="`${list.group}_speaker`">
          <ol v-if="list.group === 'Day1'" class="filters-container row">
            <!-- Speaker -->
            <li v-for="speaker in list.speaker" :key="speaker.name" class="col-12 col-sm-6 col-lg-3 px-2 mb-3 filters-item" :data-category="list.group">
              <a class="card h-100 transition-3d-hover" data-toggle="modal" :data-target="'#' + speaker.data_target" style="cursor:pointer">
                <div class="card-body">
                  <div class="avatar avatar-xl avatar-circle mb-4" style="border: 2px solid #f4f5fa">
                    <img class="avatar-img" :src="require('@/assets/img/apidays-2023/speakers/' + speaker.image_url)">
                  </div>
                  <h3>{{ speaker.name }}</h3>
                  <p>{{ speaker.job_title }}</p>
                </div>
              </a>
              <!-- Modal -->
              <div :id="speaker.data_target" class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="speaker.data_target + 'Title'" aria-hidden="true">
                <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <!-- Header -->
                    <div class="modal-top-cover bg-primary text-center">
                      <figure class="position-absolute right-0 bottom-0 left-0">
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                        </svg>
                      </figure>

                      <div class="modal-close">
                        <button type="button" class="btn btn-icon btn-sm btn-ghost-light" data-dismiss="modal" aria-label="Close">
                          <svg width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                          </svg>
                        </button>
                      </div>

                      <figure class="w-100 position-absolute bottom-0 left-0">
                        <img class="img-fluid" src="@/assets/svg/components/isometric-lego.svg">
                      </figure>
                    </div>
                    <!-- End Header -->

                    <!-- Speaker Profile  -->
                    <div class="modal-top-cover-avatar">
                      <img class="avatar avatar-xl avatar-circle avatar-border-lg avatar-centered shadow-soft" :src="require('@/assets/img/apidays-2023/speakers/' + speaker.image_url)">
                    </div>
                    <div class="text-center m-3 mx-5">
                      <h2 class="mb-0">{{ speaker.name }}</h2>
                      <p class="mb-1">{{ speaker.position }}</p>
                      <p v-if="speaker.company" class="font-weight-bold">{{ speaker.company }}</p>
                      <!-- <a :href="require('@/assets/img/events/apidays-live-hongkong/speakers/' + speaker.media_card)" class="btn btn-xs btn-soft-secondary" download>
                        <i class="fas fa-download mr-1" /> Download Social Media Card
                      </a> -->
                    </div>
                    <!-- End Speaker Profile  -->

                    <!-- Body -->
                    <div class="modal-body pb-sm-5 px-sm-5">
                      <div v-if="speaker.about" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">about</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <p v-for="item in speaker.about" :key="item">
                            {{ item }}
                          </p>
                          <div v-if="speaker.about_special" v-html="speaker.about_special" />
                        </div>
                      </div>

                      <div v-if="speaker.topic_title" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">speaking topic</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <h3 class="mb-2">{{ speaker.topic_title }}</h3>
                          <span v-if="speaker.topic_description">
                            <p v-for="item in speaker.topic_description" :key="item">
                              {{ item }}
                            </p>
                          </span>
                          <div v-if="speaker.topic_special" v-html="speaker.topic_special" />
                          <div v-if="speaker.topic_title_2" class="mt-6">
                            <h3 class="mb-2">{{ speaker.topic_title_2 }}</h3>
                            <span v-if="speaker.topic_description_2">
                              <p v-for="item in speaker.topic_description_2" :key="item">
                                {{ item }}
                              </p>
                            </span>
                            <div v-if="speaker.topic_special_2" v-html="speaker.topic_special_2" />
                          </div>
                        </div>
                      </div>

                      <div v-if="(speaker.linkedin || speaker.twitter)" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">get connected</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <ul class="list-inline mb-0">
                            <li v-if="speaker.linkedin" class="list-inline-item">
                              <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://www.linkedin.com/in/' + speaker.linkedin + '/'" target="_blank">
                                <i class="fab fa-linkedin" />
                              </a>
                            </li>
                            <li v-if="speaker.twitter" class="list-inline-item">
                              <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://twitter.com/' + speaker.twitter" target="_blank">
                                <i class="fab fa-twitter" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="text-center mt-9">
                        <button type="button" class="btn btn-wide btn-primary transition-3d-hover" data-dismiss="modal" aria-label="Close">Back to Speaker List</button>
                      </div>
                    </div>
                    <!-- End Body -->
                  </div>
                </div>
                <!-- End Modal -->
              </div>
            </li>
            <!-- End Speaker -->
          </ol>
        </span>
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5 mb-md-6 mt-8">
          <h3>Day 2 Speakers</h3>
        </div>
        <!-- Day 2 Speakers-->
        <span v-for="list in speaker_list" :key="`${list.group}_speaker`">
          <ol v-if="list.group === 'Day2'" class="filters-container row">
            <!-- Speaker -->
            <li v-for="speaker in list.speaker" :key="speaker.name" class="col-12 col-sm-6 col-lg-3 px-2 mb-3 filters-item" :data-category="list.group">
              <a class="card h-100 transition-3d-hover" data-toggle="modal" :data-target="'#' + speaker.data_target" style="cursor:pointer">
                <div class="card-body">
                  <div class="avatar avatar-xl avatar-circle mb-4" style="border: 2px solid #f4f5fa">
                    <img class="avatar-img" :src="require('@/assets/img/apidays-2023/speakers/' + speaker.image_url)">
                  </div>
                  <h3>{{ speaker.name }}</h3>
                  <p>{{ speaker.job_title }}</p>
                </div>
              </a>
              <!-- Modal -->
              <div :id="speaker.data_target" class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="speaker.data_target + 'Title'" aria-hidden="true">
                <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <!-- Header -->
                    <div class="modal-top-cover bg-primary text-center">
                      <figure class="position-absolute right-0 bottom-0 left-0">
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                        </svg>
                      </figure>

                      <div class="modal-close">
                        <button type="button" class="btn btn-icon btn-sm btn-ghost-light" data-dismiss="modal" aria-label="Close">
                          <svg width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                          </svg>
                        </button>
                      </div>

                      <figure class="w-100 position-absolute bottom-0 left-0">
                        <img class="img-fluid" src="@/assets/svg/components/isometric-lego.svg">
                      </figure>
                    </div>
                    <!-- End Header -->

                    <!-- Speaker Profile  -->
                    <div class="modal-top-cover-avatar">
                      <img class="avatar avatar-xl avatar-circle avatar-border-lg avatar-centered shadow-soft" :src="require('@/assets/img/apidays-2023/speakers/' + speaker.image_url)">
                    </div>
                    <div class="text-center m-3 mx-5">
                      <h2 class="mb-0">{{ speaker.name }}</h2>
                      <p class="mb-1">{{ speaker.position }}</p>
                      <p v-if="speaker.company" class="font-weight-bold">{{ speaker.company }}</p>
                      <!-- <a :href="require('@/assets/img/events/apidays-live-hongkong/speakers/' + speaker.media_card)" class="btn btn-xs btn-soft-secondary" download>
                        <i class="fas fa-download mr-1" /> Download Social Media Card
                      </a> -->
                    </div>
                    <!-- End Speaker Profile  -->

                    <!-- Body -->
                    <div class="modal-body pb-sm-5 px-sm-5">
                      <div v-if="speaker.about" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">about</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <p v-for="item in speaker.about" :key="item">
                            {{ item }}
                          </p>
                          <div v-if="speaker.about_special" v-html="speaker.about_special" />
                        </div>
                      </div>

                      <div v-if="speaker.topic_title" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">speaking topic</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <h3 class="mb-2">{{ speaker.topic_title }}</h3>
                          <span v-if="speaker.topic_description">
                            <p v-for="item in speaker.topic_description" :key="item">
                              {{ item }}
                            </p>
                          </span>
                          <div v-if="speaker.topic_special" v-html="speaker.topic_special" />
                          <div v-if="speaker.topic_title_2" class="mt-6">
                            <h3 class="mb-2">{{ speaker.topic_title_2 }}</h3>
                            <span v-if="speaker.topic_description_2">
                              <p v-for="item in speaker.topic_description_2" :key="item">
                                {{ item }}
                              </p>
                            </span>
                            <div v-if="speaker.topic_special_2" v-html="speaker.topic_special_2" />
                          </div>
                        </div>
                      </div>

                      <div v-if="(speaker.linkedin || speaker.twitter)" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">get connected</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <ul class="list-inline mb-0">
                            <li v-if="speaker.linkedin" class="list-inline-item">
                              <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://www.linkedin.com/in/' + speaker.linkedin + '/'" target="_blank">
                                <i class="fab fa-linkedin" />
                              </a>
                            </li>
                            <li v-if="speaker.twitter" class="list-inline-item">
                              <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://twitter.com/' + speaker.twitter" target="_blank">
                                <i class="fab fa-twitter" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="text-center mt-9">
                        <button type="button" class="btn btn-wide btn-primary transition-3d-hover" data-dismiss="modal" aria-label="Close">Back to Speaker List</button>
                      </div>
                    </div>
                    <!-- End Body -->
                  </div>
                </div>
                <!-- End Modal -->
              </div>
            </li>
            <!-- End Speaker -->
          </ol>
        </span>

      </div>
    </div>
    <!-- ========== End Speakers Section ========== -->

    <!-- ========== Participants Section ========== -->
    <div class="space-2" style="background-color:#ffffff;">
      <div class="container">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Participants</span>
          <h2>In 2022, 1000+ Participants from</h2>
        </div>
        <div class="mt-5 text-center">
          <a
            class="js-fancybox media-viewer transition-3d-hover"
            data-hs-fancybox-options="{
                    &quot;speed&quot;: 700
                }"
            :data-src="require('@/assets/img/events/apidays-live-hongkong/2022/images/apidays-hongkong-2022-participants.jpg')"
            data-caption="Apidays Hong Kong 2022 Participants"
            style="cursor: pointer;"
          >
            <img class="img-fluid rounded shadow" src="@/assets/img/events/apidays-live-hongkong/2022/images/apidays-hongkong-2022-participants.jpg" alt="Apidays Hong Kong 2022 Participants Logo Wall">
          </a>
        </div>
      </div>
    </div>
    <!-- ========== End Participants Section ========== -->

    <!-- ========== Sponsors Section ========== -->
    <div class="" style="background-color:#f5f9fc;">
      <div class="container space-top-2 space-bottom-1">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Selected Sponsors</span>
          <h2>Apidays Connect Hong Kong 2023 is Sponsored by</h2>
        </div>

        <div v-for="list in sponsor_list" :key="list.type" class="mb-9">
          <div class="text-center">
            <span class="divider mb-2 lead font-weight-bold">{{ list.type }}</span>
          </div>
          <div class="row text-center align-items-center" :class="list.css_class">
            <div v-for="sponsor in list.sponsor" :key="sponsor.image_url" class="col-12" :class="sponsor.css_class">
              <img class="img-fluid" :class="sponsor.image_css" :src="require('@/assets/img/apidays-2023/organizers/' + sponsor.image_url)">
            </div>
          </div>
        </div>

        <!-- CTA -->
        <div class="w-lg-85 mx-lg-auto mt-8 mb-11">
          <div class="card text-white overflow-hidden p-4" style="background-color: #65a3d1">
            <div class="row justify-content-md-start align-items-md-center text-center text-md-left">
              <div class="col-md-6 offset-md-3 mb-3 mb-md-0">
                <h3 class="text-white mb-0">Interested in sponsoring apidays Connect Hong Kong 2023?</h3>
              </div>
              <div class="col-md-3 text-md-right">
                <a id="ga-cc-cta-type-1-button" class="btn btn-light transition-3d-hover" href="https://forms.office.com/Pages/ResponsePage.aspx?id=LLfXUlCtHkGdT8oFy6wChyaOGdzghXZHl7uXgaoQPGZURUgxWE5XQVlQS0hHU0FNTTJFM1lXSE0xVi4u" target="_blank">Be a Sponsor</a>
              </div>
            </div>

            <!-- SVG Component -->
            <figure class="w-25 d-none d-md-block content-centered-y ml-n4">
              <img class="img-fluid" src="@/assets/svg/illustrations/communication.svg">
            </figure>
            <!-- End SVG Component -->
          </div>
        </div>

        <!-- Sponsor Description -->
        <div class="w-md-80 mx-auto">
          <h3 class="mb-4">More About Our Sponsors</h3>
          <div id="basicsAccordion">
            <!-- NGINX -->
            <div class="card shadow-none mb-3" style="background-color:#f5f9fc;">
              <div id="nginxHeading" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#nginxCollapse" aria-expanded="false" aria-controls="nginxCollapse" style="background-color:#f5f9fc;">
                  NGINX (Part of F5)
                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="nginxCollapse" class="collapse" aria-labelledby="nginxHeading" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <div class="row align-items-center">
                    <div class="col-12 col-lg-3">
                      <img class="img-fluid" src="@/assets/img/apidays-2023/organizers/nginx-f5.png" style="max-width:10rem">
                    </div>
                    <div class="col-12 col-lg-9">
                      <p>NGINX, now a part of F5, Inc., is the company behind the popular open source project, NGINX. F5 NGINX delivers cloud-native, Kubernetes-friendly open source and enterprise solutions that drive mission-critical apps and APIs with scalability, visibility, security, and governance.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Software AG -->
            <div class="card shadow-none mb-3" style="background-color:#f5f9fc;">
              <div id="softwareagHeading" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#softwareagCollapse" aria-expanded="false" aria-controls="softwareagCollapse" style="background-color:#f5f9fc;">
                  Software AG
                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="softwareagCollapse" class="collapse" aria-labelledby="softwareagHeading" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <div class="row align-items-center">
                    <div class="col-12 col-lg-3">
                      <img class="img-fluid" src="@/assets/img/apidays-2023/organizers/softwareag.png" style="max-width:10rem">
                    </div>
                    <div class="col-12 col-lg-9">
                      <p>Software AG's specializes in discovering effective API management methods for enterprises. By maximizing API potential beyond the gateway layer, companies can unlock the full potential for innovation and success. Being a global enterprise solution provider, its technology creates the digital backbone that integrates applications, devices, data and clouds; empowers streamlined processes; and connects "things" like sensors, devices and machines.  With a focus on API management, Software AG is determined in promoting its API offering to unlock enterprise potential in Hong Kong.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- SUSE -->
            <div class="card shadow-none mb-3" style="background-color:#f5f9fc;">
              <div id="suseHeading" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#suseCollapse" aria-expanded="false" aria-controls="suseCollapse" style="background-color:#f5f9fc;">
                  SUSE
                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="suseCollapse" class="collapse" aria-labelledby="suseHeading" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <div class="row align-items-center">
                    <div class="col-12 col-lg-3">
                      <img class="img-fluid" src="@/assets/img/apidays-2023/organizers/suse.png" style="max-width:10rem">
                    </div>
                    <div class="col-12 col-lg-9">
                      <p>SUSE is a global leader in innovative, reliable, secure enterprise-grade open source solutions, relied upon by more than 60% of the Fortune 500 to power their mission-critical workloads. We specialize in Business-critical Linux, Enterprise Container Management and Edge solutions, and collaborate with partners and communities to empower our customers to innovate everywhere – from the data center, to the cloud, to the edge and beyond.</p>
                      <p>SUSE puts the “open” back in open source, giving customers the agility to tackle innovation challenges today and the freedom to evolve their strategy and solutions tomorrow. The company employs more than 2,000 people globally. SUSE is listed on the Frankfurt Stock Exchange.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- FabriXAPI -->
            <div class="card shadow-none mb-3" style="background-color:#f5f9fc;">
              <div id="fabrixHeading" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#fabrixCollapse" aria-expanded="false" aria-controls="fabrixCollapse" style="background-color:#f5f9fc;">
                  FabriXAPI
                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="fabrixCollapse" class="collapse" aria-labelledby="fabrixHeading" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <div class="row align-items-center">
                    <div class="col-12 col-lg-3">
                      <img class="img-fluid" src="@/assets/img/apidays-2023/organizers/fabrixapi.png" style="max-width:10rem">
                    </div>
                    <div class="col-12 col-lg-9">
                      <div>FabriXAPI is cloud-based API portal service offers a range of features that are designed to make API management simple and streamlined. From branded API portal themes to API subscription and monetization, we offer everything you need to manage your APIs with ease. Whether you're using AWS or Kong, our platform integrates seamlessly with a range of API gateways.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Moongate -->
            <div class="card shadow-none mb-3" style="background-color:#f5f9fc;">
              <div id="moongateHeading" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#moongateCollapse" aria-expanded="false" aria-controls="moongateCollapse" style="background-color:#f5f9fc;">
                  Moongate
                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="moongateCollapse" class="collapse" aria-labelledby="moongateHeading" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <div class="row align-items-center">
                    <div class="col-12 col-lg-3">
                      <img class="img-fluid" src="@/assets/img/apidays-2023/organizers/moongate.png" style="max-width:10rem">
                    </div>
                    <div class="col-12 col-lg-9">
                      <p>Moongate is a web3 utility platform for brands and event organisers to seamlessly create, distribute, and integrate NFT tickets and memberships.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- On-us -->
            <div class="card shadow-none mb-3" style="background-color:#f5f9fc;">
              <div id="onusHeading" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#onusCollapse" aria-expanded="false" aria-controls="onusCollapse" style="background-color:#f5f9fc;">
                  On-us
                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="onusCollapse" class="collapse" aria-labelledby="onusHeading" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <div class="row align-items-center">
                    <div class="col-12 col-lg-3">
                      <img class="img-fluid" src="@/assets/img/apidays-2023/organizers/on-us.png" style="max-width:10rem">
                    </div>
                    <div class="col-12 col-lg-9">
                      <p>Incentive-focused PaaS leveraging AI for digital vouchers, data analytics, and integrated tech solutions, driving customer loyalty and business success.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End sponsors Section ========== -->

    <!-- ========== Past year Section ========== -->
    <div style="background-color:#ffffff;">
      <div class="container space-2">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5">
          <h2 class="mb-3">A Look Back at the Past Conferences</h2>
          <p>Thanks for all your support to Apidays Live Hong Kong 2020-2022.<br>Let's find out what happened in the past few years.</p>
        </div>

        <div class="w-lg-80 mx-auto mt-8 mb-11">
          <div class="card overflow-hidden p-5 bg-primary">
            <div class="row justify-content-md-start align-items-md-center text-center text-md-left z-index-2">
              <div class="col-md-8 col-lg-9 mb-3 mb-md-0 pl-lg-5">
                <h3 class="mb-0 text-white">Interested to View More Replay Videos of Our Past Events?</h3>
              </div>
              <div class="col-md-4 col-lg-3 text-md-right">
                <a id="" class="btn btn-light transition-3d-hover" href="javascript:;" data-toggle="modal" data-target="#oahResourcesModal">Watch Now</a>
              </div>
            </div>
            <figure class="w-35 position-absolute top-0 left-0 mt-n11 ml-n11">
              <img class="img-fluid" src="@/assets/svg/components/half-circle-2.svg">
            </figure>
          </div>
        </div>

        <div class="row mx-auto">
          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://www.benovelty.com/events/apidays-live-hongkong-2022" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong-2022.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <h4>Apidays Live Hong Kong 2022</h4>
                  <p class="font-size-1">API-First Digital Transformation & Platform Economy</p>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>
          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://www.benovelty.com/events/apidays-live-hongkong-2021" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong-2021.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <h4>Apidays Live Hong Kong 2021</h4>
                  <p class="font-size-1">API Ecosystem &amp; Data Interchange</p>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>
          <div class="col-md-4 mb-0">
            <a class="card h-100 transition-3d-hover" href="https://www.benovelty.com/events/apidays-live-hongkong" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <h4>Apidays Live Hong Kong 2020</h4>
                  <p class="font-size-1">The Open API Economy: Finance-as-a-Service &amp; API Ecosystems</p>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Past year Section ========== -->

    <!-- ========== Past Featured Press Section ========== -->
    <div style="background-color:#f5f9fc;">
      <div class="container space-2 space-bottom-3">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5">
          <h2 class="mb-3">Featured Press in the Past</h2>
          <p>Find out what the community says about our events.</p>
        </div>

        <div class="row mx-auto">
          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://hk.finance.yahoo.com/news/apidays-hong-kong-2022-open-021500445.html" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/press/yahoo-20220811.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <p class="font-size-1">Yahoo Finance</p>
                  <h4>Apidays Hong Kong 2022: Open API, API-First Transformation & Platform Economy in Hong Kong</h4>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <p class="small">August 11, 2022</p>
              </div>
            </a>
          </div>
          <div class="col-md-4 mb-4 mb-md-0">
            <a class="card h-100 transition-3d-hover" href="https://sg.finance.yahoo.com/news/apidays-hong-kong-2021-deep-071000247.html" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/press/yahoo-20210817.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <p class="font-size-1">Yahoo Finance</p>
                  <h4>Apidays Hong Kong 2021: Deep Diving the Open API, Open Banking & Data Ecosystem in Hong Kong</h4>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <p class="small">Aug 17, 2021</p>
              </div>
            </a>
          </div>
          <div class="col-md-4 mb-0">
            <a class="card h-100 transition-3d-hover" href="https://www.hkstp.org/news-room/hkstp-and-benovelty-spark-digital-economy-drive-with-apidays-live-hong-kong/" target="_blank">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/press/hk01-20201123.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <p class="font-size-1">HKSTP</p>
                  <h4>HKSTP and beNovelty Spark Digital Economy Drive with Apidays LIVE HONG KONG</h4>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <p class="small">November 4, 2020</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Past year Section ========== -->

    <!-- ========== Subscription Section ========== -->
    <!-- <div class="container-fluid space-2 space-top-lg-3 space-bottom-lg-2 gradient-y-sm-primary">
      <div class="row justify-content-sm-center align-items-lg-center text-center">
        <div class="col-lg-3 d-none d-lg-block" />
        <div class="col-sm-8 col-lg-6">
          <div class="mb-5">
            <h2>Interested in Apidays Live Hong Kong 2021 Event Highlights?</h2>
            <div class="w-lg-80 mx-auto">
              <p>Subscribe to our newsletter and receive exclusive replay videos &amp; presentation slides of the conference!</p>
            </div>
          </div>
          <div class="w-md-75 w-lg-80 mx-auto form-subscribe">
            <form ref="myform" />
          </div>
        </div>
        <div class="col-lg-3 d-none d-lg-block" data-aos="fade-left">
          <div class="max-w-33rem w-100 transform-rotate-2 ml-auto">
            <div class="device device-iphone-x">
              <img class="device-iphone-x-frame" src="@/assets/svg/illustrations/subscribe-mobile.svg">
            </div>

            <figure class="max-w-19rem w-100 position-absolute top-0 left-0 z-index-n1 mt-n5 ml-n5">
              <img class="img-fluid" src="@/assets/svg/components/dots-2.svg">
            </figure>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Subscription Section ========== -->

    <!-- ========== Subscription Section ========== -->
    <div class="container-fluid space-2">
      <div class="container">
        <div class="text-center cta-bg" data-aos="fade-up">
          <h2>Be the First to Know</h2>
          <p>Subscribe to our e-newsletter to be the first to receive the latest news and promotions of apidays Hong Kong.</p>
          <div class="w-md-90 w-lg-65 mx-auto form-subscribe mt-5">
            <!-- Begin Mailchimp Signup Form -->
            <div id="mc_embed_signup">
              <form id="mc-embedded-subscribe-form" ref="mcEmbeddedSubscribeForm" action="https://apidays.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=702d87e737&amp;f_id=006793e6f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="blank">
                <div class="mc-field-group">
                  <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                  </label>
                  <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text" />
                </div>
                <div class="mb-4">
                  <p class="small">By submitting your information, you agree to receive future communications from beNovelty.</p>
                </div>
                <div hidden="true"><input type="hidden" name="tags" value="2972031"></div>
                <div id="mce-responses" class="clear">
                  <div id="mce-error-response" class="response" style="display:none" />
                  <div id="mce-success-response" class="response" style="display:none" />
                </div>
                <!-- // real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b928e5178a4f8a241b088732f_dae542b6e6" tabindex="-1" value=""></div>
                <div class="clear"><input id="mc-embedded-subscribe" type="submit" value="Submit" name="subscribe" class="btn btn-primary border-0 mx-auto m-0"></div>
              </form>
            </div>
            <!--End mc_embed_signup-->

            <!-- <form ref="subscriptionForm" /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Subscription Section ========== -->

    <!-- ========== Popup Modals ========== -->
    <div id="oahResourcesModal" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="oahResourcesModalTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="oahResourcesModal" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body text-center">
            <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
            <h3>Replay Videos of apidays Hong Kong Conference Series</h3>
            <p>Don't miss out on this opportunity! Register below to gain immediate access and start watching the replay videos of our events.</p>
            <!-- Begin Mailchimp Signup Form -->
            <div id="mc_embed_signup">
              <form id="mc-embedded-subscribe-form" ref="mcEmbeddedSubscribeForm" action="https://apidays.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=702d87e737&amp;f_id=006793e6f0" method="post" name="mc-embedded-subscribe-form" class="validate m-0" target="blank">
                <div class="row">
                  <div class="col col-md-6">
                    <!-- First name -->
                    <div class="mc-field-group w-100">
                      <label for="mce-FNAME">First Name <span class="asterisk">*</span>
                      </label>
                      <input id="mce-FNAME" type="text" name="FNAME" class="required text" value="" required="">
                    </div>
                  </div>
                  <div class="col col-md-6">
                    <!-- Last name -->
                    <div class="mc-field-group w-100">
                      <label for="mce-LNAME">Last Name <span class="asterisk">*</span>
                      </label>
                      <input id="mce-LNAME" type="text" name="LNAME" class="required text" value="" required="">
                    </div>
                  </div>
                </div>
                <!-- Email -->
                <div class="mc-field-group w-100">
                  <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                  </label>
                  <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                </div>
                <div class="mb-4">
                  <p class="small text-center">By submitting your information, you agree to receive future communications from apidays.hk and beNovelty.</p>
                </div>
                <div hidden="true"><input type="hidden" name="tags" value="2972031"></div>
                <div id="mce-responses" class="clear">
                  <div id="mce-error-response" class="response" style="display:none" />
                  <div id="mce-success-response" class="response" style="display:none" />
                </div>
                <!-- // real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b928e5178a4f8a241b088732f_dae542b6e6" tabindex="-1" value=""></div>
                <div class="clear"><input id="mc-embedded-subscribe" type="submit" value="Submit" name="subscribe" class="btn btn-primary btn-wide border-0 mx-auto m-0"></div>
              </form>
            </div>
            <!--End mc_embed_signup-->
          </div>
        </div>
      </div>
    </div>

    <div id="registerModal" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="registerModalTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="registerModal" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body text-center pt-0">
            <!-- <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
            <p>This resource is now available on <strong>OpenAPIHub</strong>—our one-stop API Community Platform.<br>Please log in to the platform, or register as an OpenAPIHub member for FREE to watch the resource now!</p>
            <a id="" href="https://hub.openapihub.com/member/community-updates/20220824-Apidays-Hong-Kong-2022/resources" target="_blank" class="btn btn-primary transition-3d-hover mb-5">Watch on OpenAPIHub</a> -->
            <!-- Begin Mailchimp Signup Form -->
            <div id="mc_embed_signup">
              <form id="mc-embedded-subscribe-form" ref="mcEmbeddedSubscribeForm" action="https://apidays.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=702d87e737&amp;f_id=006793e6f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="blank">
                <div class="text-center">
                  <h2>Registration for the audience will be opening soon!</h2>
                  <img class="img-fluid max-w-19rem mx-auto mb-3" src="@/assets/svg/illustrations/community-apply.svg">
                  <p>Subscribe to our mailing list and be the first to know when registration opens, in addition to receiving early notifications.</p>
                </div>
                <div class="mc-field-group">
                  <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                  </label>
                  <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text" />
                </div>
                <div class="mb-4">
                  <p class="small">By submitting your information, you agree to receive future communications from beNovelty or OpenAPIHub by beNovelty.</p>
                </div>
                <div hidden="true"><input type="hidden" name="tags" value="2972031"></div>
                <div id="mce-responses" class="clear">
                  <div id="mce-error-response" class="response" style="display:none" />
                  <div id="mce-success-response" class="response" style="display:none" />
                </div>
                <!-- // real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b928e5178a4f8a241b088732f_dae542b6e6" tabindex="-1" value=""></div>
                <div class="clear"><input id="mc-embedded-subscribe" type="submit" value="Submit" name="subscribe" class="btn btn-primary border-0 mx-auto m-0"></div>
              </form>
            </div>
            <!--End mc_embed_signup-->
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="recapSubscribeForm" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="recapSubscribeFormTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="recapSubscribeForm" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <div class="text-center">
                <h4>Subscribe to our newsletter and watch playback videos NOW!</h4>
                <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
              </div>
              <form ref="recapSubscribeForm" />
              <div v-if="isSubmitted" class="modal-submitted-content">
                <div class="w-lg-80 mx-auto text-center">
                  <hr>
                  <h5 class="my-5">You may find the playback videos and discover other resources of Apidays Live Hong Kong 2021 below:</h5>
                  <router-link class="card card-frame p-3" :to="{ path: '/events/apidays-live-hongkong-2021/recap' }">
                    <div class="d-flex align-items-center">
                      <figure class="max-w-6rem w-100">
                        <img class="img-fluid" src="@/assets/svg/icons/icon-41.svg">
                      </figure>
                      <div class="pl-3 font-weight-bold">Watch Apidays 2021 Recap NOW!</div>
                      <div class="ml-auto"><i class="fas fa-chevron-right" /></div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Popup Modals ========== -->
  </main>
</template>

<script>
const $ = require('jquery')
import OrganiserBar from '@/components/organiser-bar'
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import HSVideoPlayer from '../../../assets/vendor/hs-video-player/src/js/hs-video-player'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.fancybox.js'
import '../../../assets/vendor/fancybox/dist/jquery.fancybox.js'
import 'bootstrap'

export default {
  name: 'Apidays2023',
  components: {
    OrganiserBar
  },
  data() {
    return {
      isSubmitted: false,
      gallery_list: [
        {
          image_url: '1.jpg',
          class: 'col-md-3 mb-3'
        },
        {
          image_url: '2.jpg',
          class: 'col-md-5 mb-3'
        },
        {
          image_url: '3.jpg',
          class: 'col-md-4 mb-3'
        },
        {
          image_url: '4.jpg',
          class: 'col-md-4 mb-3'
        },
        {
          image_url: '5.jpg',
          class: 'col-md-5 mb-3'
        },
        {
          image_url: '6.jpg',
          class: 'col-md-3 mb-3'
        },
        {
          image_url: '7.jpg',
          class: 'col-md-4 mb-3'
        },
        {
          image_url: '8.jpg',
          class: 'col-md-5 mb-3'
        },
        {
          image_url: '9.jpg',
          class: 'col-md-3 mb-3'
        },
        {
          image_url: '10.jpg',
          class: 'col-md-3 mb-3'
        },
        {
          image_url: '11.jpg',
          class: 'col-md-5 mb-3'
        },
        {
          image_url: '12.jpg',
          class: 'col-md-4 mb-3'
        },
        {
          image_url: '13.jpg',
          class: 'col-md-4 mb-3'
        },
        {
          image_url: '14.jpg',
          class: 'col-md-5 mb-3'
        },
        {
          image_url: '15.jpg',
          class: 'col-md-3 mb-3'
        },
        {
          image_url: '16.jpg',
          class: 'col-md-3 mb-3'
        },
        {
          image_url: '17.jpg',
          class: 'col-md-5 mb-3'
        },
        {
          image_url: '18.jpg',
          class: 'col-md-4 mb-3'
        }
      ],
      gallery_list_2: [
        {
          image_url: '1.png',
          class: 'col-md-3 mb-3'
        },
        {
          image_url: '2.png',
          class: 'col-md-5 mb-3'
        },
        {
          image_url: '3.png',
          class: 'col-md-4 mb-3'
        },
        {
          image_url: '4.png',
          class: 'col-md-4 mb-3'
        },
        {
          image_url: '5.png',
          class: 'col-md-5 mb-3'
        },
        {
          image_url: '6.png',
          class: 'col-md-3 mb-3'
        }
      ],
      organiser_list: {
        title: 'Apidays Connect Hong Kong 2023',
        image_path: 'apidays-2023',
        css: 'space-2',
        organiser: [
          {
            post: 'Lead Organizer',
            image_url: 'benovelty.png',
            css: ''
          },
          {
            post: 'Co-organizer',
            image_url: 'hkstp-2023.png',
            css: 'column-divider-md column-divider-20deg px-md-3'
          },
          {
            post: 'Global Partner',
            image_url: 'apidays-2023.png',
            css: 'column-divider-md column-divider-20deg px-md-3'
          }
        ]
      },
      selected_group: 'Day1',
      speaker_list: [
        {
          group: 'Day1',
          title: 'Day 1',
          speaker: [
            {
              data_target: 'albertModal',
              image_url: 'albert-pro.jpg',
              name: 'Dr. Albert Lam',
              job_title: 'CTO & Chief Scientist at Fano Labs',
              company: 'Fano Labs',
              position: 'CTO & Chief Scientist',
              about: [
                'Dr Albert Lam received his BEng degree (First Class Honors) in Information Engineering and his PhD degree in Electrical and Electronic Engineering (EEE), both from The University of Hong Kong (HKU), in 2005 and 2010, respectively. He was a postdoctoral scholar at the Department of Electrical Engineering and Computer Sciences of University of California, Berkeley. He is now the Chief Technology Officer and the Chief Scientist at Fano Labs, a deep-tech startup specializing in speech and language technologies enabled by AI. He also serves as an Adjunct Assistant Professor in both EEE and Geography at HKU, and a Croucher research fellow. He is a member in the Expert Committee of Shenzhen Artificial Intelligence Industry Association and a Senior Member of IEEE. His research interests include optimization, AI, intelligent transportation system, and smart city. He is an Associate Editor of IEEE Transactions on Evolutionary Computation, IEEE Transactions on Artificial Intelligence, IEEE Transactions on Emerging Topics in Computational Intelligence, and IEEE Transactions on Intelligent Transportation Systems.'
              ],
              topic_title: 'Panel Discussion: Innovation Success: Technology Enablement Through API',
              linkedin: 'albert-lam-15595a14'
            },
            {
              data_target: 'alvinModal',
              image_url: 'alvin-pro.jpg',
              name: 'Alvin Tam',
              job_title: 'HKCS EASG Executive Committee at HKCS',
              company: 'HKCS',
              position: 'HKCS EASG Executive Committee',
              about: [
                'Alvin Tam has more than 20 years of experience in spearheading various system development, which include architecture, design, and implementation, IT governance and management, as well as digital transformation. He is currently serving as the Head of Digital Development at MNC retail company who manages the omni-channel E-commerce platforms that involve over 500 million customers in more than ten countries in Asia.',
                'Alvin has worked in multiple places, such as Hong Kong, Australia, the United States, and mainland China. Acting as a transformation agent between business stakeholders and IT talents, he provides professional multi-disciplinary experience and knowledge with a series of professional certifications in CISA, PMP, certified management accountant (CMA), an Executive Committee of Hong Kong Computer Society (HKCS) and a full member of Institute of Electrical and Electronics Engineers (IEEE).'
              ],
              topic_title: 'Panel Discussion: Generative AI & API',
              linkedin: 'alvin-tam-a3010527'
            },
            {
              data_target: 'andrewModal',
              image_url: 'andrew-pro.jpg',
              name: 'Andrew Chan',
              job_title: 'VP, Growth and Data at Apoidea Group',
              company: 'Apoidea Group',
              position: 'VP, Growth and Data',
              about: [
                'Specialized in digital transformation and data visualization, Andrew Chan is the VP, growth and data in Apoidea group, lecturer in HK Baptist university, and founding fellow of Data Literacy Association. He has been working in various companies on data analytics including foodpanda, Casetify, and SCMP.'
              ],
              topic_title: 'Panel Discussion: The Power of Data x API'
            },
            {
              data_target: 'arthurModal',
              image_url: 'arthur-pro.jpg',
              name: 'Arthur Siu',
              job_title: 'Head of Product and Proposition at Zurich Insurance',
              company: 'Zurich Insurance',
              position: 'Head of Product and Proposition',
              about: [
                'Arthur is currently heading the Product and Proposition team in Zurich Hong Kong. He is responsible for overseeing the product proposition development and management of all non-life personal line products and driving new initiatives with different ecosystem partners. Arthur began his career as a management consultant, and has taken on various roles in strategy, research and product development across the technology, asset management, insurance, and reinsurance sectors within the Asia Pacific region.'
              ],
              topic_title: 'Panel Discussion: Open API & Embedded Insurance',
              linkedin: 'arthursiu'
            },
            {
              data_target: 'barryModal',
              image_url: 'barry-pro.jpg',
              name: 'Barry Chan',
              job_title: 'Chief Digital Office and Head of FINNOSpace at FORMS HK',
              company: 'FORMS HK',
              position: 'Chief Digital Office and Head of FINNOSpace',
              about: [
                'Barry is the Chief Digital Officer of FORMS HK and is in charge of FINNOSpace, or "FORMS INNOvaion Space", which focuses on the development of virtual asset and cryptocurrency ecosystems.',
                'Barry has a reputation for providing trustworthy advice to institutions that are undergoing the fintech revolution. Prior to joining FORMS, Barry was formerly IBM Consulting Partner - Financial Services Sector Leader, with 20+ years of experience in consultancy business.'
              ],
              topic_title: 'Panel Discussion: Fintech & API',
              linkedin: 'barry-chan-061a0518'
            },
            {
              data_target: 'benModal',
              image_url: 'ben-pro.jpg',
              name: 'Ben Cheng',
              job_title: 'CEO at FormX.ai / Oursky',
              company: 'FormX.ai / Oursky',
              position: 'CEO',
              topic_title: 'Panel Discussion: Generative AI & API',
              linkedin: 'chpapa'
            },
            {
              data_target: 'brianModal',
              image_url: 'brian-pro.jpg',
              name: 'Brian Chan',
              job_title: 'Advisory CTO at Radica Systems Limited',
              company: 'Radica Systems Limited',
              position: 'Advisory CTO',
              about: [
                'Brian Chan is the Advisory CTO of Radica Systems Limited, a leading Marketing Automation company, with focus to use data to grow the value of customer data.',
                'Before joining Radica, Brian held several IT leadership roles in adidas, Carlsberg, PVH Corp., Jebsen and Gammon Construction. He has won the 2022 CIO 100 awards.',
                'One of Brian\'s key initiatives is to drive the adoption of APIs as a digital Lego system. He believes that APIs serve as building blocks that can seamlessly connect data from their source of truth. By establishing a Common Data Model, a collection of all raw operational data, Brian aims to enable the identification of insights, trends, and abnormalities, which eventually empowers organizations to make informed decisions.'
              ],
              topic_title: 'Panel Discussion: The Power of Data x API',
              linkedin: 'brian-chan-09799412'
            },
            {
              data_target: 'crystalModal',
              image_url: 'crystal-pro.jpg',
              name: 'Crystal Fok',
              job_title: 'Head of STP Platform at HKSTP',
              company: 'HKSTP',
              position: 'Head of STP Platform',
              about: [
                'Dr Crystal Fok is currently the Head of STP Platform at Hong Kong Science and Technology Parks Corporation (HKSTP). She is mainly responsible for various Innovation and Technology related initiatives in Science Park to drive technology adoption in industries, especially in Real Estate, Logistics, Banking & Finance and Healthcare sectors.',
                'Prior to HKSTP, Dr Fok was a Manager at ASTRI, managing a diverse project portfolio, such as market analysis, feasibility studies, product development and technology commercialization. She was also a Consultant at the Hong Kong Productivity Council, managing the optical design team and providing consultancy service on precision manufacturing.',
                'Dr Fok received her PhD degree in Mechanical and Automation Engineering from the CUHK and her research interests include A.I., Robotics, MEMs and micro-optics.'
              ],
              topic_title: 'API Opens Up Massive Business Opportunities',
              topic_description: [
                'Business opportunities in building collaboration through API'
              ],
              linkedin: 'crystal-fok-8892853a'
            },
            {
              data_target: 'daveModal',
              image_url: 'dave-pro.jpg',
              name: 'Dave Chen',
              job_title: 'Vice President at Hong Kong Computer Society',
              company: 'Hong Kong Computer Society',
              position: 'Vice President',
              about: [
                'With over 20 years of experience in IT management and consultancy, Dave has a proven track record in driving IT strategic planning and digital transformation. As Head of IT, he successfully managed a large team of over a hundred staff members, including IT managers, architects, business analysts, and scrum team developers, while advocating for the adoption of Enterprise, Digital, Cloud, Security, AI, and Big Data Architecture.',
                'Dave holds a Master\'s degree in Software Engineering and has obtained professional qualifications in Security, Cloud, and Data Science. He is widely recognized as a leader in the IT Enterprise Architects community, with established relationships with ICT associations. Dave is an active speaker, columnist, AWS Authorized Instructor Champion, and Microsoft Certified Trainer, with a proven ability to speak confidently at large ICT forums and conferences in Asia.',
                'Dave is passionate about driving innovation and leveraging technology to enhance business performance. He has a wealth of expertise in IT management, enterprise architecture, and digital transformation, which has enabled him to deliver high-impact solutions that drive business growth and success.'
              ],
              topic_title: 'Panel Discussion: Generative AI & API',
              linkedin: 'dowchen'
            },
            // {
            //   data_target: 'davepModal',
            //   image_url: 'davep-pro.jpg',
            //   name: 'Dave Pemberton',
            //   job_title: 'Sr. Director, Product Management, Integration at Software AG',
            //   company: 'Software AG',
            //   position: 'Sr. Director, Product Management, Integration',
            //   about: [
            //     'Dave is a Product Manager, thought leader and evangelist within the Enterprise Software space, specialising in API Management, Hybrid Integration, Microservices, Devops, Robotic Process Automation and IoT with a proven track record of delivering to the highest standards for some of the UK\'s largest and global organisations across many sectors.'
            //   ],
            //   topic_title: 'Unlocking the Power of Data through Open APIs',
            //   topic_description: [
            //     ''
            //   ],
            //   linkedin: 'pembertondave'
            // },
            {
              data_target: 'dennisModal',
              image_url: 'dennis-pro.jpg',
              name: 'Dennis Shi',
              job_title: 'Founder & CEO at On-us Company Limited',
              company: 'On-us Company Limited',
              position: 'Founder & CEO',
              about: [
                'Dennis SHI is an experienced entrepreneur and CEO who currently leads On-us Group, a FinTech & MarTech company that provides a new, consumer-minded B2B digital voucher solution. The company is headquartered in Cyberport, Hong Kong with branches in Singapore and Taiwan. On-us is an incentives ecosystem that is focused on delivering value and deepening customer relationships through customized messages and analysis of consumer behavioral data.',
                'Dennis brings over 20 years of experience in converging vertical knowledge of Financial Services, Telecommunication, Media, Technology, Retails and Supply Chain to his role as CEO.',
                'Prior to founding his own Fintech start-ups, Dennis held senior management positions in several Fortune 500 multinational Information Technology and Telecommunication groups such as Atos, CenturyLink, NTT Com Asia, and T-Systems. He led the teams to provide digital transformation consulting, cross-border payment solutions and platforms integration services. Dennis holds a Global MBA from the University of Manchester Worldwide, and has actively participated in various social responsibility and fund-raising activities, such as ACCA Charity Rickshaw race, Salvation Army Night Orienteering, Médecins Sans Frontières Orienteering, UNESCO Run for Peace, Operation Santa Claus, etc. Dennis is also a frequent speaker at industry events and is known for his expertise in the areas of digital transformation, fintech, and entrepreneurship.'
              ],
              topic_title: 'Panel Discussion: Open API & Embedded Insurance',
              linkedin: ''
            },
            {
              data_target: 'easonModal',
              image_url: 'eason-pro.jpg',
              name: 'Eason Lai',
              job_title: 'Global Technology Strategist at Microsoft',
              company: 'Microsoft',
              position: 'Global Technology Strategist',
              about: [
                'A seasoned Principal Digital Solution Architect with over 20 years of experience in application development, cloud technologies, digital solutions, big data solutions, data analytics & science, and experiences in supporting various industries & enterprises. Working experience includes multinational technology giant companies & financial institutions, Microsoft & IBM, and AIA Hong Kong & Macau.',
                'As a technical evangelist and strategist, he is very active in public speeches, webinars, and contributions of technical articles and open-source code repositories, whose topics include Artificial Intelligence, Machine Learning, Data Analytics & Science, Solution Design & Best Practices with cloud technologies. Also, he mentored data analytics and science specialization courses in Coursera (Deeplearning.ai) & was awarded Microsoft MVP of Artificial Intelligence.'
              ],
              topic_title: 'The Future of IT Architecture with Generative AI and API',
              topic_description: [
                'Generative AI is a branch of artificial intelligence that can create new content, such as text, images, audio, and code, based on existing data and user inputs. An API, or application programming interface, is a set of defined rules that enable different applications to communicate with each other and use generative AI models through the Internet or private network. It acts as an intermediary layer that processes data transfers between systems, letting developers access and customize generative AI models in their applications. In this presentation, you will learn how generative AI and API can transform IT architecture by enabling rapid prototyping, customization, integration, and deployment of generative AI applications across different domains and platforms. You will also see some examples of generative AI applications built with API, such as chatbots, content generators, and creative assistants. Finally, you will learn how to build responsibly with generative AI and consider the potential harms and risks of using large language models. Join us for this exciting session and discover the power and possibilities of generative AI and API!'
              ],
              linkedin: 'eason-lai-8618a235'
            },
            {
              data_target: 'ivanModal',
              image_url: 'ivan-pro.jpg',
              name: 'Ivan Lau',
              job_title: 'CEO at Pantheon Lab Limited',
              company: 'Pantheon Lab Limited',
              position: 'CEO',
              about: [
                'Ivan is the Co-Founder and Chief Executive Officer of Pantheon Lab Limited, an AI technology company that leverages deep learning to develop cutting-edge Generative AI Technologies. Their innovative solutions, including digital assistant and digital human technology are crafted to enable brands and corporations to advance their businesses and revolutionize their marketing campaigns.'
              ],
              topic_title: 'Panel Discussion: Innovation Success: Technology Enablement Through API',
              linkedin: 'ivan-lau'
            },
            {
              data_target: 'jedModal',
              image_url: 'jed-pro.jpg',
              name: 'Jed Ng',
              job_title: 'Founder at Angel School',
              company: 'Angel School',
              position: 'Founder',
              about: [
                'Jed Ng is the Founder of AngelSchool.vc. They help angel investors build their own syndicates.',
                'He\'s a self-taught angel who has backed 2 startups from seed to unicorn. Today, he is backed by 1000+ LPs. He previously built the world\'s largest API Marketplace with RapidAPI- an a16z-backed startup.'
              ],
              topic_title: 'Panel Discussion: API Startup Trends & Fund Raising',
              topic_title_2: 'APIs and Venture Capital',
              linkedin: 'jedng'
            },
            {
              data_target: 'jenniferModal',
              image_url: 'jennifer-pro.jpg',
              name: 'Jennifer Leung',
              job_title: 'Manager, Community Engagement at HKSTP',
              company: 'HKSTP',
              position: 'Manager, Community Engagement',
              about: [
                'Jennifer works as an ambassador of STP Platform, bridging tech startups and corporates, promoting the power of data, tools, digital services and environments for next-gen innovation. She has also a believer in values driven from cross industry data collaboration, forming Hong Kong\'s first city-wide data community. ',
                'STP Platform is a membership-based community that fosters data-centric collaborations, an ecosystem of data owners & requesters, technology enablers, startups and young talents. STP Platform provides a wide range of upskill programmes, facilitating stakeholders at every stage of project development. In today\'s digital era, it is fascinating to see how businesses evolve towards making smarter decisions, impacting lives with data-driven intelligence. With a member pool of 600+ companies, Jennifer engages with community, designs events and campaigns to member needs, turning unseen opportunities into reality!'
              ],
              topic_title: 'Panel Discussion: Innovation Success: Technology Enablement Through API',
              linkedin: 'jennifer-leung-343746238'
            },
            {
              data_target: 'jerryModal',
              image_url: 'jerry-pro.jpg',
              name: 'Jerry Ying',
              job_title: 'Chief Products Officer at Transunion Asia Pacific',
              company: 'Transunion Asia Pacific',
              position: 'Chief Products Officer',
              about: [
                'Jerry Ying\'s current role as the Vice President in Transunion Asia Pacific leading innovative solution group division which mainly manage the product management and product development of TU innovative products including Fraud & ID, Decision Services, Credit Information Services and Analytics & Consulting Services. Jerry has over 25 years of work experiences with covering technology, merge & acquisition, product management & development, strategic planning and business operation and leadership management functions.',
                'Jerry has a long history of work experience in information services with big data analytics and business solution experience covering credit risk management and marketing solution aspect. Jerry has been worked as the general manager in Wanda Credit Services Limited as subsidiary of Wanda Group (one of the China largest commercial retail management business with asset over 250 shopping malls, 1500 cinema including AMC, China largest intelligent car park businesses, file production company and leading internet payment services provider in China. Prior to Wanda, Jerry has also worked in Experian for over 11 years worked in different roles including general manager of credit services in Greater China, general manager of decision analytics in Greater China, COO of Greater China, commercial director of marketing analytics in APAC, Corporate development Sr. manager covering different aspect of information services business.'
              ],
              topic_title: 'Panel Discussion: The Power of Data x API',
              linkedin: 'jerry-ying-1a20968'
            },
            {
              data_target: 'jigarModal',
              image_url: 'jigar-pro.jpg',
              name: 'Jigar Bhansali',
              job_title: 'VP Solution Architecture, APJ at Software AG',
              company: 'Software AG',
              position: 'VP Solution Architecture, APJ',
              about: [
                'Jigar Bhansali is a seasoned technology professional and has more than 25 years of experience in helping organizations deploy technology strategies for growth and to drive better operational efficiencies. His area of expertise includes process management, hybrid data integration, API management, event processing and IoT analytics solutions which he has worked on for the past 16 years. He has been involved in numerous strategic digital innovation initiatives and provided solutions to customers and partners across the region.',
                'Jigar provides advice to organizations and help shape their digital transformation by including technology platform that offers the right balance of openness, integration and interoperability, performance, security and control and this allows organizations to have a future-proof, flexible and sustainable platform.',
                'His work experience is recognized internationally, having worked with organizations across Asia Pacific and many industries including financial services, telco, retail, process and discrete manufacturing / industry 4.0.',
                'He plays an integral role in the software AG\'s regional strategic direction, development and future growth. As an evangelist and part of the Software AG APJ Go-To-Market Sales organization he manages the team that support the Software AG\'s regional sales organization and customers.'
              ],
              topic_title: 'Unlocking the Power of Data through Open APIs',
              topic_description: [
                'The content revolves around several key areas related to data and APIs.',
                'Innovation - discuss a use case of how data and APIs have been used to drive innovation in a particular industry or business.',
                'Collaboration - explore how data and APIs can be used to facilitate collaboration between different teams or organizations.',
                'New Business - Additionally, will also touch on business opportunities, highlighting how industries are looking to leverage data and APIs to create new business models or revenue streams.'
              ],
              linkedin: 'bhansalij'
            },
            {
              data_target: 'juniModal',
              image_url: 'juni-pro.jpg',
              name: 'Juni Yan',
              job_title: 'Managing Director at Openhive',
              company: 'Openhive',
              position: 'Managing Director',
              about: [
                'Juni Yan is being appointed as the Managing Director of Openhive -- a technology brand embracing the vision to co-create a new data era by unleashing the power of data and providing enterprise-grade solutions on multi-parties data collaboration, AI and privacy computing.  Being the flagship solution, Openhive Federated Learning (OHFL), is a breakthrough machine learning technology allowing multiple organizations to collaborate in AI model training while keeping their respective proprietary data at source to safeguard data privacy.',
                'Juni is a high-tech industry veteran with more than 25 years of experience. Prior to Openhive, Juni had served various leadership roles, including Global VP of the Transport, Logistics and Automotive Industries and VP of Security in Asia, Middle East and Africa (AMEA) in BT Global Services; Managing Director for Hong Kong and Taiwan at F5 Networks; as well as different sales leadership roles in the SaaS and Application Business Units during her 15 years of tenure in Oracle.',
                'Earlier in her career, Juni worked in the Strategy Consultancy at Arthur Andersen Business Consulting advising clients on strategic decisions, ranging from China entry strategy, product and marketing strategy, to e-business strategy.'
              ],
              topic_title: 'Panel Discussion: Fintech & API',
              linkedin: 'juni-yan-88377331'
            },
            {
              data_target: 'justyModal',
              image_url: 'justy-pro.jpg',
              name: 'Justy Yuen',
              job_title: 'Section Head, Data & Analytics at Hong Kong Trade Development Council',
              company: 'Hong Kong Trade Development Council',
              position: 'Section Head, Data & Analytics',
              about: [
                'Justy Yuen is an Executive Committee Member of the Enterprise Architecture Specialist Group at the Hong Kong Computer Society (HKCS). He is the Section Head, Data & Analytics from Hong Kong Trade Development Council (HKTDC) who leads the data and analytics function.',
                'Before joining HKTDC, Justy held positions in the West Kowloon Cultural District Authority as well as various multinational corporations. His extensive experience encompasses diverse areas such as enterprise architecture, data and analytics, system integration, IT operations, infrastructure, cybersecurity, application development, and art technology.'
              ],
              topic_title: 'Panel Discussion: Generative AI & API',
              linkedin: 'justyyuen'
            },
            {
              data_target: 'kelvinModal',
              image_url: 'kelvin-pro.jpg',
              name: 'Kelvin Cheung',
              job_title: 'Principal SE at Imperva',
              company: 'Imperva',
              position: 'Principal SE',
              about: [
                'Kelvin joined Imperva over 6 years, based on Hong Kong. In Imperva, he is responsible for security consultancy services and designing architecture for application, API and data security within the IT environment. In this role, Kelvin works extensively with large enterprises particularly within the FSI and Government to secure their critical assets. He also brings decades of experience and an impressive depth of cyber security knowledge as he translates these often complex topics into accessible, actionable information.'
              ],
              topic_title: 'Panel Discussion: Open API & Embedded Insurance',
              linkedin: 'kelvin-Cheung-2160886a'
            },
            {
              data_target: 'lareinaModal',
              image_url: 'lareina-pro.jpg',
              name: 'Lareina Wang',
              job_title: 'Executive Director, Head of Digital and Innovation at DBS Bank',
              company: 'DBS Bank',
              position: 'Executive Director, Head of Digital and Innovation',
              about: [
                'Lareina Wang is Executive Director, Head of Digital and Innovation, Institutional Banking Group of DBS Bank (Hong Kong) Limited. She is responsible for managing the ban\'s wholesale digital business, P&L management, digital product and platform development as well as delivering best-in-class digital customer journeys across Hong Kong and the Greater Bay Area.',
                'Lareina is an active FinTech practitioner. She currently serves as Board Member of FinTech Association of Hong Kong and is also a member of the Banking and Finance Training Board for the Vocational Training Council.',
                'Prior to DBS Hong Kong, Lareina also held senior digital and strategy roles for HSBC UK and HSBC Hong Kong, as well as Barclays Bank and Ogilvy London.',
                'Lareina holds a BA in English Literature and a MSc in International Marketing.'
              ],
              topic_title: 'Panel Discussion: Fintech & API',
              linkedin: 'lareina-wang-王玥-575597a'
            },
            {
              data_target: 'manModal',
              image_url: 'man-pro.jpg',
              name: 'Man Li',
              job_title: 'Head of Strategic Operational Initiatives at Sun Life Hong Kong Limited',
              company: 'Sun Life Hong Kong Limited',
              position: 'Head of Strategic Operational Initiatives',
              about: [
                'Man Li is a seasoned professional with over 20 years of experience in the insurance industry. Throughout Man\'s career, he has developed a specific interest and expertise in strategic planning and delivery, business and digital transformation, change, and project management.',
                'With a passion for driving innovation and growth within the insurance sector, Man has worked with leading organizations to develop and implement strategic initiatives that deliver tangible results.',
                'Man\'s expertise in business and digital transformation has been invaluable to his clients, helping them to navigate the rapidly changing landscape of the insurance industry. He has a deep understanding of the latest technology trends and how they can be leveraged to improve business operations, enhance customer experience, and drive growth.'
              ],
              topic_title: 'Panel Discussion: Open API & Embedded Insurance',
              linkedin: 'man-li-41b02831'
            },
            {
              data_target: 'markModal',
              image_url: 'mark-pro.jpg',
              name: 'Mark Arel',
              job_title: 'Area Practice Manager at AWS',
              company: 'AWS',
              position: 'Area Practice Manager',
              about: [
                'Mark Arel is the Director of AWS Professional Services - Hong Kong. Previously, he was AWS Practice Manager - Global Financial Services based in New York City. Before joining AWS, Mr Arel was the Director/Divisional Chief Architect for Risk, Finance and Data at Capital One. Mr Arel has over 30 years\' of experience in the Financial Services industry in various senior technology executive leadership roles including Vice President/Chief Technology Innovation Officer - PNC Bank, Executive Director - UBS Investment Bank, Executive Director - CIBC World Market , and Vice President - Greenwich Capital Markets.'
              ],
              topic_title: 'APIs Enabling Open Banking',
              topic_description: [
                'Financial regulators around the world are considering, or enacting, open banking standards - regulators see open banking as a way to increase transparency, improve access, and promote single standards. Open banking technologies allow financial institutions to securely share account and customer information, and provide access to transactional execution to third parties, via an open application programming interface (API) environment  But open banking isn\'t just about regulatory compliance - open APIs have great potential when it comes to banking customer experience.'
              ],
              linkedin: 'markitect1'
            },
            {
              data_target: 'martinModal',
              image_url: 'martin-pro.jpg',
              name: 'Martin Liu',
              job_title: 'Assistant Director, Platform Development, STP Platform at HKSTP',
              company: 'HKSTP',
              position: 'Assistant Director, Platform Development, STP Platform',
              about: [
                'Martin Liu has over 10 years of working experience in artificial intelligence industry. He is a seasoned IT professional with extensive experience in developing computer vision solutions and in depth knowledge in product development. Currently Martin is the Assistant Director of STP Platform and was the AI technical lead in HKSTP.',
                'Prior to this role, Martin was leading the system design and development team of an AI company in developing computer vision solutions for diamond related technology, smart retails, and facial recognition technology. He is inventor of 4 patents. He invented the reddot winner, patented diamond viewer with algorithm for viewing nano scale marking and specific 8A8H pattern on diamond.',
                'Martin holds an Master Degree in E-Commerce and Internet Computing, and Bachelor Degree in Computer Engineering from the University of Hong Kong.'
              ],
              topic_title: 'Panel Discussion: Fintech & API',
              linkedin: 'martin-liu-522ab63b'
            },
            {
              data_target: 'matthewModal',
              image_url: 'matthew-pro.jpg',
              name: 'Matthew Zi Jin Lee',
              job_title: 'CEO at Fill Easy Limited',
              company: 'Fill Easy Limited',
              position: 'CEO',
              about: [
                'Matthew Zi Jin Lee, MsC Financial Technology from HKUST, is the founder and CEO of Fill Easy, a startup dedicated to making government data easier to use. He possesses first-hand financial and compliance experience from his previous roles in both Audit and Insurance, and from his experiences working in those fields, understands keenly the disruption that new technology and data brings to the financial industry and beyond. Matthew is passionate about Fintech, Govtech and Open Data, and acts as a mentor in programs such as “Strive and Rise” for youths interested in exploring these fields. '
              ],
              topic_title: 'Panel Discussion: Innovation Success: Technology Enablement Through API',
              linkedin: 'matthew-zi-jin-lee'
            },
            {
              data_target: 'medhyModal',
              image_url: 'medhy-pro.jpg',
              name: 'Medhy Souidi',
              job_title: 'Founder & Host at What The FinTech',
              company: 'What The FinTech',
              position: 'Founder & Host',
              about: [
                'Medhy Souidi is a passionate innovator and a driving force in the financial industry. Serving as the Head of Transformation and Ecosystems at DBS Hong Kong, one of Asia\'s top-tier banks, Medhy brings fresh ideas to life, creates customer-centric services, and ensures DBS stays on the cutting edge of fintech.',
                'Drawing from his deep industry knowledge and a wealth of experience, Medhy plays an instrumental role in the bank\'s success. As a trusted voice in fintech, AI, and the Metaverse, he\'s the go-to speaker at industry events, and his insights are eagerly sought by those eager to understand the latest in fintech.',
                'Outside his work at DBS, Medhy shares his love for all things fintech through his newsletter and podcast, "What the FinTech". Launched in 2019, the podcast and YouTube channel provide unique perspectives on the fast-paced fintech world, featuring expert interviews and wide-ranging topics. Medhy has created a vibrant community of fintech enthusiasts, providing valuable insights and networking opportunities.',
                'In addition to all of this, Medhy makes time to actively participate in industry events and initiatives. He was a board member of the Fintech Association of Hong Kong, committed to enhancing the regional fintech ecosystem. His expertise, professional network, and engaging content have earned him recognition as a leading voice in the fintech industry. He was awarded the "Fintech Changemaker of the Year" by Asian Private Banker in 2019 and identified as a young innovator by Tatler\'s Gen T list, marking his significant impact on Asia\'s financial and tech landscapes.'
              ],
              topic_title: 'Panel Discussion: Fintech & API',
              linkedin: 'medhysouidi'
            },
            {
              data_target: 'michaelaModal',
              image_url: 'michaela-pro.jpg',
              name: 'Michael Au',
              job_title: 'Associate Director, Partnership at HKSTP',
              company: 'HKSTP',
              position: 'Associate Director, Partnership',
              about: [
                'Michael Au is the Associate Director of Partnerships at Hong Kong Science and Technology Parks (HKSTP). His team provides the framework and resources to serve global leading corporations on their innovation journey.',
                'HKSTP Partnerships includes proven collaboration models including on-demand innovation matching, joint accelerator, talent development and other long term initiates around various industry verticals and technology to sharpen corporate partners\' competitive edges.',
                'Michael is a full-stack startup practitioner with 10+ years hands-on experience, focusing on business development, product design and partnerships management. He believes co-creation and commercialisation of technologies through an empowered global partnership network is the key to accelerate innovation impact.'
              ],
              topic_title: 'Panel Discussion: Open API & Embedded Insurance',
              linkedin: 'michael-wk-au'
            },
            {
              data_target: 'michaelyModal',
              image_url: 'michaely-pro.jpg',
              name: 'Michael Yung',
              job_title: 'Strategic Advisor, Google Cloud at Google Hong Kong',
              company: 'Google Hong Kong',
              position: 'Strategic Advisor, Google Cloud',
              about: [
                'Michael is currently a Strategic Advisor in Google Cloud\'s APAC Industry Technology Group, where he provides technology advice and guidance to clients on their long-term strategy. He has over 30 years of experience in IT, with a particular focus on Internet, eCommerce, and TravelTech. He is also an expert in web usability, computer security, and blockchain technologies.'
              ],
              topic_title: 'Panel Discussion: Generative AI & API',
              linkedin: 'michaelyung'
            },
            {
              data_target: 'patrickModal',
              image_url: 'patrick-pro.jpg',
              name: 'Patrick C.S. Fan',
              job_title: 'Lead organizer at apidays.hk & Founder / CEO at beNovelty',
              company: 'apidays.hk / beNovelty',
              position: 'Lead organizer at apidays.hk & Founder / CEO at beNovelty',
              about: [
                'Patrick Fan is a serial entrepreneur and CEO of beNovelty Limited - an award-winning API first company. Patrick is a recognized thought leader in API space and the Hong Kong organizer & representatives of APIDays - the world\'s Leading API Conference Series. He is an active participant & speaker of major API Developer Communities in different regions. Patrick is current an entrepreneur-in-residency (EIR) of a venture capital and providing API advisory to various local startups & enterprise across sectors to adopt API first strategy. With his exceptional knowhow in Open API domain, Patrick is part of the Hong Kong API Startup Registration Scheme workgroup and is appointed by HKQAA as an associate trainer in API Cybersecurity & Information Security.'
              ],
              topic_title: 'Day 1—Panel Disucssion: Innovation Success: Technology enablement through API',
              topic_title_2: 'Day 2—How an API Developer Portal can Drive Growth',
              linkedin: 'patrickcsfan'
            },
            {
              data_target: 'richardModal',
              image_url: 'richard-pro.jpg',
              name: 'Richard Lord',
              job_title: 'MD, Regional CIO, Wholesale Bank - ASP at HSBC',
              company: 'HSBC',
              position: 'MD, Regional CIO, Wholesale Bank - ASP',
              about: [
                'Richard Lord is the Chief Information Officer, Asia Pacific for HSBC Commercial Bank. He has a 25 year+ career working with contemporary and new technologies to change business, reach customers and deliver new services and propositions. Previously, Richard held a global technology leadership role in a leading advertising holding company and was an entrepreneur for 17 years, founding and operating one of the very first web technology companies in Australia in the early 1990\'s. In his current role, Richard leads a regional team focused on creating excellent new customer experiences for commercial banking, leveraging new technologies and digital channels. Security, customer confidentially and privacy are paramount.'
              ],
              topic_title: 'Future of Connected Banking',
              topic_description: [
                'With APIs and Connected Banking continuing to evolve, Richard will take an opportunity to discuss some of the achievements and progress to date, and to share his view of opportunities for the Future of Connected Banking.'
              ],
              linkedin: 'richardalord'
            },
            {
              data_target: 'shainModal',
              image_url: 'shain-pro.jpg',
              name: 'Shain Singh',
              job_title: 'Principal Security Architect at NGINX (part of F5)',
              company: 'NGINX (part of F5)',
              position: 'Principal Security Architect',
              about: [
                'Shain is the lead Security Architect at F5 where he has been works with organisations across the Asia Pacific, China and Japan regions. At F5 he also collaborates with the Open Source Program Office (OSPO) and Office of CTO (OCTO). Shain also is a Project co-lead for the OWASP Machine Learning Security Top 10 project.'
              ],
              topic_title: 'Safeguarding APIs: Ensuring Security in Connected Ecosystems',
              topic_description: [
                'The rich ecosystem for APIs is made up of services and solutions which have helped drive innovation. The integration and connectedness of these services however, can lead to concerns about supply chains. We will talk about how security controls can still enable agility and work at the pace of deployment cycles.'
              ]
            },
            {
              data_target: 'toaModal',
              image_url: 'toa-pro.jpg',
              name: 'Dr. Toa Charm',
              job_title: 'Founding Chairman at Data Literacy Association',
              company: 'Data Literacy Association',
              position: 'Founding Chairman',
              about: [
                'Dr. Charm has amassed over three decades of experience in the innovation and technology industry. He previously served as the Chief Public Mission Officer of Cyberport and held senior executive positions at leading multinational, Chinese, and Hong Kong enterprises such as IBM, Oracle, HSBC, Jardine Pacific, and Kingdee. Dr. Charm specializes in digital strategy and transformation, corporate innovation, intrapreneurship, and start-up cultivation. He possesses technical expertise in areas such as big data, AI, fintech, blockchain, and e-commerce.',
                'Currently, Dr. Charm holds the position of Chairman and Principal Advisor at several elite Asia-based start-ups, including OpenCertHub Academy, Wizpresso, Buyandship, Posify, HairCoSys, and more. He provides digital transformation and data monetization advisory services to leading enterprises such as Transunion, HSBC, foodpanda, Hong Kong Airport Authority.',
                'Dr. Charm is an associate director and professor (EMBA) at the Chinese University of Hong Kong. He has designed and delivered executive courses to influential enterprises in Asia such as Chow Tai Fook, Harbour City, AIA, Bank of East Asia, ICBC, CCB.'
              ],
              topic_title: 'Panel Discussion: The Power of Data x API',
              linkedin: 'toacharm'
            },
            {
              data_target: 'tonyModal',
              image_url: 'tony-pro.jpg',
              name: 'Tony Tung',
              job_title: 'Managing Director at Gobi Partners GBA',
              company: 'Gobi Partners GBA',
              position: 'Managing Director',
              about: [
                'Tony joined Gobi\'s Hong Kong office in 2016, and currently focuses on building Hong Kong and Southern China Greater Bay Area (GBA) startup ecosystems, as well as the Southeast Asia and the Middle East markets. Tony has focused on and invested in more than 70 start-up companies globally, in the fields of artificial intelligence, semiconductors, new materials, industry 4.0/smart manufacturing, cross-border e-commerce & logistics, Internet service SaaS, industrial Internet, Web 3.0 and financial technology (FinTech). Tony has accumulated extensive experience in growing high-tech businesses from his two previous startups related to financial technology and enterprise solutions in big data. He led the overall product roadmap and management, business development & operation, technical algorithm/infrastructure design and implementation, user experience design as well as fundraising for both of the companies. Tony received his B.Eng. in Computer Engineering and B.B.A. in Financial Engineering double degree programme at The Chinese University of Hong Kong.'
              ],
              topic_title: 'Panel Discussion: API Startup Trends & Fund Raising',
              linkedin: 'tonytungwohon'
            },
            {
              data_target: 'vishalModal',
              image_url: 'vishal-pro.jpg',
              name: 'Vishal Ghariwala',
              job_title: 'Senior Director and CTO, Asia Pacific at SUSE',
              company: 'SUSE',
              position: 'Senior Director and CTO, Asia Pacific',
              about: [
                'Vishal Ghariwala is the Senior Director and Chief Technology Officer for SUSE for the Asia Pacifc region. In this capacity, he engages with customer and partner executives across the region. In addition, he is responsible for growing SUSE\'s mindshare by being the executive technical voice to the market, press, and analysts. Vishal also has a global charter with the SUSE Office of the CTO to assess relevant industry, market and technology trends to identify growth, partnering and M&A opportunities.',
                'Prior to joining SUSE, Vishal was the Director for Cloud Native Applications at Red Hat where he led a team of senior technologists to drive the growth and adoption of the Red Hat OpenShift, API Management, Integration and Business Automation portfolios across the Asia Pacific region. Before that, he spent a significant amount of time with leading middleware vendors such as IBM, ILOG and Intalio, as well as the public sector.',
                'Vishal has over 20 years of experience in the IT industry and holds a Bachelor\'s Degree in Electrical and Electronic Engineering from the Nanyang Technological University in Singapore.'
              ],
              topic_title: 'Securing the Financial Sector: Mitigating Cyber Threats to APIs',
              topic_description: [
                'For financial service institutions (FSIs), APIs are both engines of innovation and the source of competitive differentiation. Incorporating APIs as part of the overall IT strategy can help FSIs deliver seamless experiences for developers, customers, and partners to win in the digital economy. However the increased adoption of APIs also introduces new security challenges for FSIs. In this session, we will discuss common cyber threats and attacks faced by FSIs and strategies that they can adopt to strengthen their security posture.'
              ],
              linkedin: 'vishalghariwala'
            }
          ]
        },
        {
          group: 'Day2',
          title: 'Day 2',
          speaker: [
            {
              data_target: 'aminModal',
              image_url: 'amin-pro.jpg',
              name: 'Amin Abbaspour',
              job_title: 'Senior Manager at Okta',
              company: 'Okta',
              position: 'Senior Manager',
              topic_title: 'Common OAuth2 Mistakes To Avoid',
              about: [
                'Amin looks after Okta Professional Services in APAC. Aim is passionate about helping customers build and secure their applications and get the best value out of their investment with Okta. Besides work, Amin loves spending time with his kids, reading and weightlifting.'
              ],
              topic_description: [
                'In this talk, we show a couple of common mistakes developers make when securing their API access with OAuth2, describe the problem in each case and provide best practices to achieve the same requirement without pitfalls.'
              ],
              linkedin: 'abbaspour'
            },
            {
              data_target: 'asankaModal',
              image_url: 'asanka-pro.jpg',
              name: 'Asanka Abeysinghe',
              job_title: 'CTO at WSO2, INC',
              company: 'WSO2, INC',
              position: 'CTO',
              about: [
                'Asanka is a technology visionary who connects people and technology through digital transformation programs that create consumer-centric applications. As WSO2\'s Chief Technology Officer, he is responsible for advancing the company\'s corporate reference architecture and promoting its thought leadership vision. Collaborating with customers, partners, and industry analysts, he drives WSO2\'s technology mission. With over 20 years of experience in designing and implementing scalable distributed systems, SOA, microservice architectures, and business integration solutions, Asanka is a contributor to the Apache Software Foundation and a sought-after speaker at global events and tech meetups.'
              ],
              topic_title: 'API-First Internal Dev Platform: Drive Digital Experiences',
              topic_description: [
                'In today\'s experience economy, creating unique digital experiences has become a competitive advantage. However, delivering these experiences is challenging, and organizations often struggle to translate their strategies into value. Technology leaders frequently find themselves focusing on building internal developer platforms to reduce complexity and enhance productivity, rather than concentrating on digital innovation. This requires substantial investments in time, money, and specialized skill sets.',
                'As a strategist or a craftsman embarking on a digital transformation journey, you might be pondering:',
                'Why is having an internal developer platform crucial for success?',
                'What are the characteristics of an effective internal developer platform in the context of the modern experience economy and cloud-native architecture?',
                'How does an internal developer platform accelerate an organization\'s digital strategy and boost the efficacy of digital strategists?',
                'In this session, Asanka will address these questions by sharing real-life stories from the industry and reference implementations that utilize a market-leading internal developer platform as a service.'
              ],
              linkedin: 'asankaabeysinghe'
            },
            {
              data_target: 'brentonModal',
              image_url: 'brenton-pro.jpg',
              name: 'Brenton House',
              job_title: 'Vice President, Digital Evangelism at Software AG',
              company: 'Software AG',
              position: 'Vice President, Digital Evangelism',
              about: [
                'Brenton House is Vice President of Digital Evangelism at Software AG.  He is an Digital Strategist focused on Artificial Intelligence (AI), Cybersecurity, and API Integrations that equip organizations to succeed as they navigate the world of tomorrow. Brenton is known for his unique creative work on his popular YouTube channel where he talks about AI, Cybersecurity, and APIs.'
              ],
              topic_title: 'Security and Secrets: AI and APIs in the Battle for Privacy',
              topic_description: [
                'The battle for privacy is a dynamic and ever-changing landscape. In this talk, we\'ll explore how AI and APIs are unlocking new potential in security, creating robust defenses against breaches and unauthorized access while also opening new vulnerabilities that must be addressed.  We\'ll examine the latest technologies, methodologies, and strategies that are revolutionizing the way we protect your data. From AI-powered integrations to threat detection to API-driven access controls, discover how we can harness the power of AI APIs to fortify privacy without stifling innovation.'
              ],
              linkedin: 'brentonhouse'
            },
            {
              data_target: 'cliveModal',
              image_url: 'clive-pro.jpg',
              name: 'Clive Chan',
              job_title: 'Principal Solution Architect at Openhive',
              company: 'Openhive',
              position: 'Principal Solution Architect',
              about: [
                'Clive has over twenty years of experience in technical consulting and business development. By working in various technical roles for systems integrators and IT vendors, Clive has gained extensive experience in multi-vendor integration projects and architecture design for enterprise customers.',
                'Clive has extensive enterprise architecture and technology knowledge spanning from Secure Multi-party computation, multi-cloud infrastructure and API security. In addition, he has personally been involved in designing and deploying many of the largest and most innovative enterprise infrastructures in Hong Kong and the Region.'
              ],
              topic_title: 'Federated Learning - Privacy Preserving and Scalable Generative AI',
              topic_description: [
                'Generative AI and Large Language Models (LLMs) revolutionize the AI landscape, while inherent challenges include high computational resource requirements, data privacy issues, and dataset scaling limitations.',
                'In this session, we delve into the concept of federated learning. The benefits of federated learning and example use cases in the region. Finally, we examine how federated learning can bolster LLMs through parameter-efficient fine-tuning strategies for industrial applications.'
              ],
              linkedin: 'clive-chan-hk'
            },
            {
              data_target: 'daleModal',
              image_url: 'dale-pro.jpg',
              name: 'Dale Lane',
              job_title: 'Chief Architect, Senior Technical Staff Member, IBM Event Automation at IBM',
              company: 'IBM',
              position: 'Chief Architect, Senior Technical Staff Member, IBM Event Automation',
              about: [
                'Dale is the Chief Architect for IBM Event Integration - responsible for helping enterprises to take advantage of event streaming and stream processing with technologies like Apache Kafka and Flink. He is also a member of the AsyncAPI technical steering committee - an open standard from the Linux Foundation for documenting event driven architectures and applications. '
              ],
              topic_title: 'API Management for an Asynchronous World',
              topic_description: [
                'Applying the discipline of API management to messaging and streaming platforms like Apache Kafka is an exciting, emerging area, that may soon become as common-place as using API management for REST/HTTP APIs.',
                'In this talk, I will describe how the evolution of API management could apply to the asynchronous world, looking ahead at what aspects from API management could make sense for event endpoints and how they might translate, and what aspects perhaps won\'t apply.'
              ],
              linkedin: 'dalelane'
            },
            {
              data_target: 'derricModal',
              image_url: 'derric-pro.jpg',
              name: 'Derric Gilling',
              job_title: 'CEO at Moesif',
              company: 'Moesif',
              position: 'CEO',
              about: [
                'Derric Gilling is the CEO of Moesif, the leading API analytics and monetization platform. He enjoys helping API first businesses leverage usage-based pricing and analytics to fuel product-led growth. Gilling is a frequent speaker on API strategy at developer conferences, including API World, Developer Week, Collision, APIDays and has published a report with O\'Reilly, API Strategy for Decision Makers.'
              ],
              topic_title: 'How to Align API Metrics to Product Objectives',
              topic_description: [
                'There are no shortage of API metrics you could track, but how do you aligned to business outcomes. This session takes a deep dive on how to align metrics to business goals such as: Adoption, Engagement, and Retention. Then, we\'ll discuss changes you can make to your API strategy for improving these areas.'
              ],
              linkedin: 'derricgilling'
            },
            {
              data_target: 'dipinModal',
              image_url: 'dipin-pro.jpg',
              name: 'Dipin Behl',
              job_title: 'Principal Engineer at Stryker',
              company: 'Stryker',
              position: 'Principal Engineer',
              about: [
                'Dipin has over sixteen years of professional experience in designing and developing web, desktop, mobile , AR/VR based software solutions which find their applicability in many wide array of industries. He has been instrumental in driving digital transformation for large to mid scale organizations. He thrives in driving innovation within teams and collaboratively moving towards the ultimate goal.',
                'In his current role as Principal Engineer at Stryker, he helps bring to life state of art digital solutions that assist surgeons in achieving better patient outcomes while reducing patient exposure at a faster pace. Outside his cubical, he loves talking cricket, football and basketball, taking his camera on a long motorcycle road trip to the mountains or simply spending time with his two naughty sons and a beautiful wife.'
              ],
              topic_title: 'Zero Trust Architecture - API developer\'s prospective',
              topic_description: [
                'Zero Trust Architecture has become more important with the more decentralized and cloud based setup that has become the new normal in all industries. Though that is a shared responsibility for each section of the digital setup of an organization, we as API developers have a crucial role to play as well. During this talk, we would walk through what a Zero Trust Setup means for an organization and where we share the responsibility. We would also look at how industry standards are shaping up the security implementation in our APIs and how do we get started at implementing them.'
              ],
              linkedin: 'dipinbehl'
            },
            {
              data_target: 'diveshModal',
              image_url: 'divesh-pro.jpg',
              name: 'Divesh Gupta',
              job_title: 'Vice President, Technology & Sales Operations at PCCW Global',
              company: 'PCCW Global',
              position: 'Vice President, Technology & Sales Operations',
              about: [
                'Divesh has over 25 years of experience in the international telecommunication market. In his current role at PCCW Global, Divesh leads several software development teams engaged in digital transformation of international data connectivity services. A certified SAFe Agile practitioner, he focuses on enhancing customer experience and reducing turnaround times by improving the efficiency of the sales team and automating existing manual business processes. Current initiatives include: (1) API development for inter-carrier sales process automation for trading of international connectivity services and (2) Blockchain based financial settlements within Telecom vertical (3) A custom designed CPQ (Configure-Price-Quote) application called QuickQuote, This automation cuts down the turnaround time for quote responses to PCCW Global\'s customers from several weeks to under a few minutes.',
                'Divesh is Co-Chair of the Test & Certification committee at MEF; is an ONF certified SDN associate (OCSA); has a MEF-CECP certification. He attained his MBA from University of Iowa, Tippie School of Management in May of 2006 and also holds an Engineering degree in Electronics & Communications from Manipal Institute of Technology.'
              ],
              topic_title: 'Connecting the World: The Importance of Standardized APIs for Global Network-as-a-Service (NaaS) Services',
              topic_description: [
                'Abstract: In today\'s interconnected world, businesses and individuals rely on global NaaS services to connect with one another. As the demand for these services continues to grow, it is essential that we establish standardized APIs to ensure seamless and secure communication between different networks and providers. In this talk, I will explore the importance of using standardized APIs for global NaaS services, including the benefits of interoperability, scalability, and reduced costs. I will also discuss the challenges and potential solutions for implementing standardized APIs in the network industry. Join us to learn how standardization can pave the way for a more interconnected, efficient, and secure global network.'
              ],
              linkedin: 'diveshgupta'
            },
            {
              data_target: 'enricoModal',
              image_url: 'enrico-pro.jpg',
              name: 'Enrico Candino',
              job_title: 'Sr Software Engineer at SUSE',
              company: 'SUSE',
              position: 'Sr Software Engineer',
              topic_title: 'A Prescriptive Approach to Application Development with Epinio',
              linkedin: 'enricocandino'
            },
            {
              data_target: 'giriModal',
              image_url: 'giri-pro.jpg',
              name: 'Giri Venkatesan',
              job_title: 'Developer Advocate at Solace',
              company: 'Solace',
              position: 'Developer Advocate',
              about: [
                'Giri is an Architect & Developer Advocate with extensive experience in various technical domains, including Integration and Master Data Management. He started his engineering journey in the classic EAI & B2B Integration space and has been a part of the Integration evolution culminating in modern EDA, Microservices, Spring, and other Low-code/no-code frameworks. He has a keen interest in building and promoting creative solutions and is a huge fan of open-source standards and applications. He is excited to identify and explore tools and frameworks to aid Businesses in their quest for achieving efficiency and increased productivity.'
              ],
              topic_title: 'Bridging the Gap between Design and Runtime in EDA with AsyncAPI',
              topic_description: [
                'Event-Driven Architectures (EDAs) are gaining momentum, and AsyncAPI has emerged as a powerful tool for streamlining development, encompassing vital aspects such as documentation, code generation, discovery, and event management. However, while documentation and code generation receive ample attention, the equally critical aspects of discovery and event management often remain overlooked.',
                'In this session, we will delve into the practical application of AsyncAPI to address the challenge of keeping your EDA design and runtime in sync in today\'s fast-paced and iterative world. Through an interactive hands-on demo, we will demonstrate how AsyncAPI can seamlessly integrate with Continuous Integration and Continuous Deployment (CI/CD) practices and the Solace Event Portal to automatically update your design in response to runtime changes.',
                'By attending this session, you will gain practical insights into the implementation of AsyncAPI as a core component of your EDA workflow, understanding how to seamlessly incorporate CI/CD processes to keep your design and runtime in harmony. Our goal is to provide technical professionals with a deep understanding of the benefits and methodologies involved in using AsyncAPI to enhance discovery, event management, and maintain the synchronisation of design and runtime in real-world scenarios.'
              ],
              linkedin: 'girivenkatesan'
            },
            {
              data_target: 'graceModal',
              image_url: 'grace-pro.jpg',
              name: 'Grace Burdett',
              job_title: 'Technology Engineer at IBM',
              company: 'IBM',
              position: 'Technology Engineer',
              about: [
                'Grace is an apprentice working within IBM\'s Client Engineering practice, whilst studying towards a Technology Degree. In her time at IBM she has had the opportunity to work across numerous Proof of Concept projects for clients across the country. Through this experience she has gained knowledge in various technical expertise from integration tools to React programming.'
              ],
              topic_title: 'Deploying and Upgrading Your First API in 30 Minutes or Less',
              topic_description: [
                'Developing and deploying APIs is often seen as a complex and time-consuming task to take on. By utilising the world of API management systems, such as IBM API Connect, it doesn\'t have to be an unnerving experience (and it can be quite the opposite)!',
                'Previously, deploying an API would\'ve required multiple applications to approach the task. Now with all-in-one solutions, not only can we develop and deploy our APIs in one system - but we can handle all management of the APIs in the same environment. This includes securely managing access to the API and to the deployment of the API. On top of this, one can handle the lifecycle of the API in the same system, upgrading the version safely without leaving those on a previous version without support.',
                'In this session we\'ll dive into the world of API management; making use of IBM API Connect to deploy an API, manage access to it securely and then performing an upgrade to the API.',
                'With these cohesive demos, we\'ll utilise real-life examples with a goal of providing you with your first steps in API development and management!'
              ],
              linkedin: 'grace-burdett-702b56217'
            },
            {
              data_target: 'harishModal',
              image_url: 'harish-pro.jpg',
              name: 'Harish Kumar',
              job_title: 'Senior Cloud Architect at Google Cloud',
              company: 'Google Cloud',
              position: 'Senior Cloud Architect',
              about: [
                'Harish Kumar possesses more than 18 years of experience in the API and Integration sector. He has led data integration initiatives at various companies, overseeing significant customer interactions for pioneering technology adopters, enterprise data warehouses, and application migration and consolidation. Over the past four years, he has been part of Google, contributing to the implementation of cutting-edge API strategies using Google Cloud\'s latest technologies.'
              ],
              topic_title: 'Importance of securing your API - What, Why & How',
              topic_description: [
                'With API attacks on the rise, and existing security technology proving to be ineffective at stopping API attacks, organizations need to take a new approach.',
                'API security offerings must provide a range of functionality to be useful to organizations, including:',
                'API and sensitive data discovery to uncover undocumented APIs and potential data exposures that impact employee and user privacy',
                'API attack prevention and blocking to stop attackers early in their attack campaigns',
                'API-centric incident response to provide context for operations teams to respond quickly and effectively'
              ],
              linkedin: 'harishisin'
            },
            {
              data_target: 'jeremygModal',
              image_url: 'jeremyg-pro.jpg',
              name: 'Jeremy Glassenberg',
              job_title: 'Product Lead, APIs at DocuSign',
              company: 'DocuSign',
              position: 'Product Lead, APIs',
              about: [
                'Jeremy is an experienced Product leader of over 15 years with a specialty in managing developer platforms and APIs at successful startups like Box and Tradeshift, building successful Platform and Product organizations from the ground up. He managed and expanded developer platforms to communities of tens of thousands of developers, and led high-profile integrations promoted by Salesforce and Google.',
                'Jeremy is an advisor and mentor to startups and accelerators such as Alchemist, Acceleprise, Techstars, and Heavybit for Product and API strategy. He is also a Lead Instructor at Product School and author of components to Product School\'s core curriculum.'
              ],
              topic_title: 'API Design in Fintech: Challenges and Opportunities for next-gen APIs',
              topic_description: [
                'After years of fintech companies site-scraping bank websites, we\'re finally seeing APis.  Plaid now lets you go to Chase bank directly, log in, and get secure, reliable, API access. And as those much needed APIs came, the industry now has several “decacorns” and a longer list of unicorns.',
                'Fintech APIs came later than others, but experienced a growth spurt shocking even to the tech industry.  And while we\'ve seen well-designed APIs that adopted good standards already present, differences and inconsistencies between Fintech APIs show that these APIs aren\'t at the quality they could be. Fintech API businesses are debating internally what standards and designs work best - formats, user representations, etc - all the while, ensuring security and privacy in APIs where stakes are higher.',
                'We\'ll highlight differences among successful APIs in the space to identify the open questions that lead to more solid standards for the Fintech space.'
              ],
              linkedin: 'jglassenberg'
            },
            {
              data_target: 'jeremysModal',
              image_url: 'jeremys-pro.jpg',
              name: 'Jeremy Snyder',
              job_title: 'Founder & CEO at FireTail',
              company: 'FireTail',
              position: 'Founder & CEO',
              about: [
                'Jeremy is the founder and CEO of FireTail.io, an end-to-end API security startup. Prior to FireTail, Jeremy worked in M&A at Rapid7, a global cyber leader, where he worked on the acquisitions of 3 companies during the pandemic. Jeremy previously led sales at DivvyCloud, one of the earliest cloud security posture management companies, and also led AWS sales in southeast Asia. Jeremy started his career with 13 years in cyber and IT operations. Jeremy has an MBA from Mason, a BA in computational linguistics from UNC, and has completed additional studies in Finland at Aalto University. Jeremy speaks 5 languages and has lived in 5 countries.'
              ],
              topic_title: 'API security - analysis of breaches, attack vectors, strategies',
              topic_description: [
                'API security is a poorly understood topic. Many think that traditional security approaches will work, but they won\'t. I\'ll discuss why, built on analysis of data and technology stacks.'
              ],
              linkedin: 'jeremysnyder'
            },
            {
              data_target: 'johnnyModal',
              image_url: 'johnny-pro.jpg',
              name: 'Johnny Wong',
              job_title: 'Manager, Solutions Engineering for HK and Macau at F5',
              company: 'F5',
              position: 'Manager, Solutions Engineering for HK and Macau',
              about: [
                'Johnny Wong is an esteemed IT professional with an extensive career spanning over 20 years in the industry. Throughout his career, he has held positions both in-house and as a solutions provider, honing his expertise in various technologies including Networking, Cyber Security, Cloud, and Microservices.',
                'With a deep understanding of the internal needs and pain points across different business units, Johnny excels at providing thoughtful solutions that align with and exceed customer expectations. He adopts a consultative approach, ensuring a comprehensive understanding not only of technological aspects but also of the business perspective.',
                'Johnny has successfully completed numerous projects across diverse industries such as BFSI, Telco, Government, and Commercial sectors. In these projects, he has effectively mitigated security risks, safeguarded sensitive data, and enhanced overall availability and performance of critical applications.'
              ],
              topic_title: 'Software Vulnerability and Inventory Management Made Easy via Enterprise Support',
              topic_description: [
                'Since there are tons of open source package and tools outside. How could you able to manage open source are in compliance and lower the risks of attack as well as have a strong support team to cover all those issues. We will walk through the key criteria of capabilities of famous OSS package - NGINX and how to make it easy to manage.'
              ],
              linkedin: 'johnnycywong1'
            },
            {
              data_target: 'maryModal',
              image_url: 'mary-pro.jpg',
              name: 'Mary Grygleski',
              job_title: 'Senior Developer Advocate at DataStax',
              company: 'DataStax',
              position: 'Senior Developer Advocate',
              about: [
                'Mary is a Java Champion and a passionate Developer Advocate at DataStax, a leading data management company that champions Open Source software and specializes in Real-Time AI, Big Data, DB-as-a-Service, Streaming, and Cloud-Native systems.  With over 25 years of hands-on experience in the software engineering and technical architecture areas, she began venturing into developer advocacy in 2018 at IBM, and has never looked back since then.  She enjoys reaching out to developers and helping them succeed in their work.  Outside of her day job, she is an active tech community builder, and currently the President of the Chicago Java Users Group (CJUG).'
              ],
              topic_title: 'Enter the Brave New World of GenAI with Vector Search',
              topic_description: [
                'With ChatGPT taking center stage since the beginning of 2023, developers who have not had a chance to work with any forms of Artificial Intelligence or Machine Learning systems may find themselves either intrigued by the “maze” of new terminologies, or some may be eager to learn more, while perhaps a smaller group may not actually want to get themselves into a territory that\'s unknown to them.',
                'The truth is that, whether we like it or not, we have all been “thrust” into this new era of computing. Instead of procrastinating, let\'s start by learning about Generative AI specifically with this presentation.  We will go over the history and evolution of AI and ML, then look at how it has evolved to where it is today.  We will touch upon as many new concepts that have popped up in the last 6-9 months, which include: Generative AI (GenAI), ChatGPT, Large Language Models (LLMs), Natural Language Processing (NLP), Vector DB, and the growing importance of Vector Search.  We will also point out the new operational concerns when it comes to managing the life-cycle of a machine learning environment.  We will then look at a demo on how Vector Search is being done behind the scenes.  We will discuss the benefits of this new wave of technology as well as the challenges that it brings to the industry and the marketplace.'
              ],
              linkedin: 'mary-grygleski'
            },
            {
              data_target: 'mattModal',
              image_url: 'matt-pro.jpg',
              name: 'Matt Tanner',
              job_title: 'Head of Developer Relations at Moesif',
              company: 'Moesif',
              position: 'Head of Developer Relations',
              about: [
                'Matt is the Head of Developer Relations at Moesif, helping developers and product managers understand and use API analytics and the Moesif platform to their benefit. He is passionate about APIs, startups, and technical architecture. Matt has worked as a developer, tech lead, and architect for some of the largest financial institutions and insurers in Canada. He is always dabbling in the latest tech and applying this to his own ventures in technology.'
              ],
              topic_title: 'Getting Started with API Monetization',
              topic_description: [
                'In this talk, we will uncover what API monetization is, how organization\'s can derive revenue from their APIs (different ways of billing), and lastly how one would go about implementing such a solution for new or existing APIs.'
              ],
              linkedin: 'matt-tanner-3267aa5a'
            },
            {
              data_target: 'matthiasModal',
              image_url: 'matthias-pro.jpg',
              name: 'Dr. Matthias Biehl',
              job_title: 'API Strategist at Software AG',
              company: 'Software AG',
              position: 'API Strategist',
              about: [
                'As API strategist, Matthias helps clients discover their opportunities for innovation with APIs & ecosystems and turn them into actionable digital strategies. Based on his experience in leading large-scale API initiatives in both business and technology roles, he shares best practices and provides both strategic and practical guidance. He has stayed a techie at heart and at some point, got a Ph.D. Matthias publishes a blog at api-university.com, is the author of several books on APIs, and regularly speaks at technology conferences.'
              ],
              topic_title: 'Everything AI? What API Folks Need to Know Now',
              topic_description: [
                'So much hype around AI - social media is full of AI startups, prompt suggestions, and LLMs. For a good reason, the technology is close to magical! It was ChatGPT that has inspired the world. It has attracted 100 million users in 2 months, which makes it the fastest-growing technology ever. And now everyone asks: what does the AI trend mean for me, my company, my job, and my products? Maybe your boss has already approached you to suggest: “Let\'s add this AI thing to our product.“',
                'But what does the AI trend mean for us API practitioners? More specifically: Will APIs become obsolete … or even more important than ever before? What is the relationship between AI and APIs? What are the patterns for building AI-powered applications - and what is the role of APIs? How can we use AI to build better APIs and how can we use APIs to build a more powerful AI? Do we need to change how we build APIs? Will the AI use and discover our APIs? Should we design APIs for the AI as a consumer? Are agents the answer (Langchain etc.) or plugins for ChatGPT?',
                'In this talk we will explore these and many more questions that arise at the interface between AI and APIs.'
              ],
              linkedin: 'mbiehl'
            },
            {
              data_target: 'mehdiModal',
              image_url: 'mehdi-pro.jpg',
              name: 'Mehdi Medjaoui',
              job_title: 'Chariman APIdays Conferences at APIdays',
              company: 'APIdays',
              position: 'Chariman APIdays Conferences',
              about: [
                'I am the founder and chairman of apidays Conferences, author of the Book "Continuous API management" (O\'Reilly 2018, 2022) , maintainer of the API Industry Landscape and former co-founder of OAuth.io (acquired) and Olympe.legal. I am also invited professor at HEC MBA and EMlyon Exec MBA.'
              ],
              topic_title: 'Market trends of the 1000+ tools of API Industry Landscape',
              topic_description: [
                'Since 2017 I maintain the API Industry Landscape, an interactive industry landscape about the 1000+ tools composing the API Industry. In this talk, after a quick analysis of the API Industry Landscape in 2023, I will share the 7 main trends we can see and how as an API practioner and Program manager you can act on your decisions and planning for accelerating your API-led digital transformation.'
              ],
              linkedin: 'mehdimedjaoui'
            },
            {
              data_target: 'mingModal',
              image_url: 'ming-pro.jpg',
              name: 'Ming Wen',
              job_title: 'Apache APISIX PMC Chair at API7.ai',
              company: 'API7.ai',
              position: 'Apache APISIX PMC Chair',
              about: [
                'Apache APISIX PMC Chair, Member of Apache Software Foundation, CEO of API7.ai'
              ],
              topic_title: 'Data compliance by using API Gateway',
              topic_description: [
                'Data compliance and privacy protection are becoming increasingly important, especially for financial services firms and multinational corporations. In this topic, Ming will use several code snippets and demos to show how to parse and route API requests at the API gateway level to achieve user data compliance and protection.'
              ],
              linkedin: 'ming-wen-api7'
            },
            {
              data_target: 'naumanModal',
              image_url: 'nauman-pro.jpg',
              name: 'Nauman Ali',
              job_title: 'Product Manager at Stoplight',
              company: 'Stoplight',
              position: 'Product Manager',
              about: [
                'Meet Nauman - API enthusiast, proficient in SDK generation, API design, and governance. He\'s a trusted resource with over seven years of hands-on experience, including impactful roles at well-respected companies like Apimatic and Stoplight. But Nauman isn\'t just a behind-the-scenes kind of guy. He\'s a familiar face on the API conference circuit, always ready to share his insights on automated API governance. Passionate about promoting transparency, scalability, and automation in the API sphere, Nauman helps others navigate through this complex field. So, if you\'re seeking a seasoned expert with a talent for simplifying intricate concepts, Nauman is your go-to guide in the API universe.'
              ],
              topic_title: 'Governance for API Design at Scale',
              topic_description: [
                'As APIs become increasingly critical for modern software development, it\'s more important than ever to establish effective governance practices to ensure consistency, reuse, and collaboration across teams and organizations. In this talk, I\'ll share best practices on implementing API governance at scale, focusing on the use of style guides and shared component libraries as key tools.',
                'We\'ll start by exploring the challenges of API governance in a large organization, including the risks of inconsistency, redundancy, and siloed development. From there, we\'ll dive into the specifics of how style guides and shared libraries can help address these challenges. I\'ll share best practices for creating and maintaining style guides that reflect your organization\'s unique needs.',
                'Throughout the talk, I\'ll provide real-world examples of how these approaches have helped organizations of different sizes and industries achieve greater efficiency, consistency, and quality in their API development. Whether you\'re just starting out with API governance or looking to take your existing practices to the next level, this talk will provide valuable insights and practical tips for success.'
              ],
              linkedin: 'nauman-ali'
            },
            {
              data_target: 'olivierModal',
              image_url: 'olivier-pro.jpg',
              name: 'Olivier Vernin',
              job_title: 'Engineering Manager at SUSE',
              company: 'SUSE',
              position: 'Engineering Manager',
              about: [
                'Engineering Manager at SUSE, working on Kubernetes\' related projects such as Rancher, or Epinio.  Former Infrastructure officer of the Jenkins project. Olivier has extensive experience in building OSS tooling around development and infrastructure automation.'
              ],
              topic_title: 'A prescriptive approach to application development with Epinio',
              topic_description: [
                'Learn how you can go from commit to deploy with Epinio\'s application development engine.'
              ],
              linkedin: 'overnin'
            },
            {
              data_target: 'paulModal',
              image_url: 'paul-pro.jpg',
              name: 'Paul Brebner',
              job_title: 'Open Source Technology Evangelist at Instaclustr (by Spot by NetApp)',
              company: 'Instaclustr (by Spot by NetApp)',
              position: 'Open Source Technology Evangelist',
              about: [
                'Paul is the Open Source Technology Evangelist at Instaclustr (now part of Spot by NetApp). For the past six years, he has been learning new scalable Big Data technologies, building realistic demonstration applications, and blogging and talking about a growing list of open-source technologies including Apache Cassandra, Apache Spark, Apache Kafka, Apache ZooKeeper, Redis, OpenSearch, PostgreSQL, Cadence, and many more.',
                'Since learning to program on a VAX 11/780, Paul has extensive R&D, teaching, and consulting experience in distributed systems, technology innovation, software architecture and engineering, performance engineering, grid and cloud computing, and data analytics and machine learning.',
                'Paul has also worked at Waikato University (New Zealand), University of New South Wales (UNSW, Sydney), Commonwealth Scientific and Industrial Research Organisation (CSIRO, Australia), University College London (UCL, UK), National ICT Australia (NICTA), Australian National University (ANU), and several tech start-ups (including as a Founder/CTO). Paul has an MSc (1st Class Hons, Waikato) in Machine Learning and a BSc (Computer Science and Philosophy, Waikato).'
              ],
              topic_title: 'Spinning Your Drones with Cadence Workflows, Apache Kafka and TensorFlow',
              topic_description: [
                'In the 1st part of this talk, we\'ll introduce a real-time Drone Delivery demonstration application using a combination of two open-source technologies: Uber\'s Cadence (for stateful, scheduled, long-running workflows), and Apache Kafka (for fast streaming). With up to 2,000 (simulated) drones and deliveries in progress at once, this application generates a vast flow of spatiotemporal data.',
                'In the 2nd part of the talk, we\'ll use this platform to explore Machine Learning (ML) over streaming and drifting Kafka data with TensorFlow to try and predict which shops will be busy in advance.'
              ],
              linkedin: 'paul-brebner-0a547b4'
            },
            {
              data_target: 'robModal',
              image_url: 'rob-pro.jpg',
              name: 'Rob Dickinson',
              job_title: 'VP of Engineering at Graylog',
              company: 'Graylog',
              position: 'VP of Engineering',
              about: [
                'Rob started Resurface (now Graylog API Security) to provide a true first-party solution for API monitoring and alerting, based on open standards but with minimal assembly required. Having worked at Dell and Intel (plus a few early-stage startups) has given Rob a unique perspective on big data and API security, and deep empathy for the challenges that API providers face. When away from the computer, Rob tries his best to keep up with his wife and 3 kids in Boulder, CO.'
              ],
              topic_title: 'If we know what APIs are, why can\'t we count them?',
              topic_description: [
                'API security should start with discovering your APIs, which seems obvious at first. Most technical folks can tell a REST API from a GraphQL API, but it\'s easy to disagree about how many APIs are present in a given system. Should we count network endpoints, method/path combinations, domain names, or even GitHub repos? This talk will explore the most popular ways to count APIs, and the implications for your API security programs.'
              ],
              linkedin: 'robfromboulder'
            },
            {
              data_target: 'shahnawazModal',
              image_url: 'shahnawaz-pro.jpg',
              name: 'Shahnawaz Backer',
              job_title: 'Senior Solutions Architect at F5',
              company: 'F5',
              position: 'Senior Solutions Architect',
              about: [
                'Shahnawaz Backer is a Senior Solutions Architect with F5. He has a keen interest in modern application security, digital identity, and multi-cloud security, he focuses on building security intelligence into solutions and firmly believes in automated proactive defense. He writes on IT security at f5labs.com. He has co-authored a Redbook on access management deployment patterns and built an Open Source Capture the Flag for API security.'
              ],
              topic_title: 'Harnessing Data for Advanced API Security & Governance',
              topic_description: [
                'In an increasingly interconnected digital landscape, Application Programming Interfaces (APIs) play a critical role in enabling seamless communication and data exchange between various systems and platforms. The rapid deployment of APIs has become a fundamental aspect of today\'s dynamic software development landscape. However, this accelerated pace of API deployment has raised concerns about potential security gaps, as demonstrated by recent high-profile breaches.',
                'In the session, we will explore how security teams can collect and analyse data to implement predictive and preventive measures, specifically focusing on the insights that data can provide to understand the threats listed under OWASP API Top 10 2023.'
              ],
              linkedin: 'backers'
            },
            {
              data_target: 'stevenModal',
              image_url: 'steven-pro.jpg',
              name: 'Steven Patrick',
              job_title: 'Technology Engineer at IBM',
              company: 'IBM',
              position: 'Technology Engineer',
              about: [
                'Steven & Grace are Technology Engineers from the IBM London Client Engineering firm, specialising across a number of technologies providing PoCs to clients across the industry. API management and development has been one of their biggest interests and development points so they felt it was a great time to put their knowledge to use and go out to speak at some events. With over 6 years in the industry for Steven and a newer outlook from Grace, this combination of speakers is sure to be an interesting session!'
              ],
              topic_title: 'Deploying and Upgrading Your First API in 30 Minutes or Less',
              topic_description: [
                'Developing and deploying APIs is often seen as a complex and time-consuming task to take on. By utilising the world of API management systems, such as IBM API Connect, it doesn\'t have to be an unnerving experience (and it can be quite the opposite)!',
                'Previously, deploying an API would\'ve required multiple applications to approach the task. Now with all-in-one solutions, not only can we develop and deploy our APIs in one system - but we can handle all management of the APIs in the same environment. This includes securely managing access to the API and to the deployment of the API. On top of this, one can handle the lifecycle of the API in the same system, upgrading the version safely without leaving those on a previous version without support.',
                'In this session we\'ll dive into the world of API management; making use of IBM API Connect to deploy an API, manage access to it securely and then performing an upgrade to the API.',
                'With these cohesive demos, we\'ll utilise real-life examples with a goal of providing you with your first steps in API development and management!'
              ],
              linkedin: 'steven-patrick-9a0b9614a'
            },
            {
              data_target: 'timModal',
              image_url: 'tim-pro.jpg',
              name: 'Tim Pettersen',
              job_title: 'Head of Developer Experience at Atlassian',
              company: 'Atlassian',
              position: 'Head of Developer Experience',
              topic_title: 'APIs aren\'t enough: Why SaaS leaders are investing in IPaaS',
              topic_description: [
                'Today, everyone has an API, and many product companies are augmenting their product strategy with platform elements to spur their growth.',
                'An increasing number of SaaS companies are going beyond the usual REST/GraphQL APIs to launch managed infrastructure offerings for their developer ecosystems. Think Google AppScript, Salesforce Apex, Slack Functions, Atlassian Forge, or GitHub Actions — all are custom PaaS or IPaaS solutions designed to simplify the process of building API consumers for their respective product ecosystems.',
                'Designing and building an IPaaS is a significant investment, but can dramatically improve the security and quality of apps and integrations built for your products, while simultaneously lowering the bar for developers to get started with your APIs. In this session, I\'ll share the rationale for building an IPaaS, and the business and technical lessons we\'ve learnt at Atlassian over the past 24 months that the Forge platform has been in General Availability. Software Engineers, DevOps Engineers, and Technical Product Managers working in the platform space will get the most from this talk.'
              ],
              linkedin: 'tim-pettersen-5833974'
            },
            {
              data_target: 'tracyModal',
              image_url: 'tracy-pro.jpg',
              name: 'Tracy Walker',
              job_title: 'Sr Security Engineer at SUSE (NeuVector by SUSE)',
              company: 'SUSE (NeuVector by SUSE)',
              position: 'Sr Security Engineer',
              about: [
                'Tracy Walker is a Sr Security engineer with over 20 years\' experience internal I.T. developing and operating custom software from start-ups to Fortune 100.  Specializing in container and Kubernetes security, Tracy currently assists Suse customers automating zero trust runtime security using the opensource project NeuVector.'
              ],
              topic_title: 'Zero Effort Zero Trust to Secure API Endpoints in Kubernetes',
              topic_description: [
                'While there are many facets to consider when discussing API Data & Privacy, the cornerstone of the ability to control Data and maintain privacy is security.  With more API-driven workloads leveraging containers and Kubernetes in cloud environments, there emerges new abstractions, new attack vectors, and the need for additional security layers.  NeuVector by Suse is an new opensource security platform specifically designed to implement zero trust runtime security and network inspection that can secure in-transit API calls for workloads running in containerized environments.'
              ],
              linkedin: 'tracyfwalker'
            }
          ]
        }
      ],
      sponsor_list: [
        {
          type: 'GOLD SPONSORS',
          css_class: 'w-100 mx-auto',
          sponsor: [
            {
              css_class: 'col-12 col-lg-4 mb-2 mb-lg-0',
              image_css: 'max-w-35rem',
              image_url: 'nginx-f5.png'
            },
            {
              css_class: 'col-12 col-lg-4 mb-2 mb-lg-0',
              image_css: 'max-w-35rem',
              image_url: 'softwareag.png'
            },
            {
              css_class: 'col-12 col-lg-4',
              image_css: 'max-w-35rem',
              image_url: 'suse.png'
            }
          ]
        },
        {
          type: 'SILVER SPONSORS',
          css_class: 'w-100 w-md-80 w-lg-65 mx-auto',
          sponsor: [
            {
              css_class: 'col-12',
              image_css: 'max-w-23rem',
              image_url: 'fabrixapi.png'
            }
          ]
        },
        {
          type: 'BRONZE SPONSORS',
          css_class: 'w-100 w-md-80 w-lg-65 mx-auto',
          sponsor: [
            {
              css_class: 'col-md-6 mb-2 mb-md-0',
              image_css: 'max-w-19rem',
              image_url: 'moongate.png'
            },
            {
              css_class: 'col-md-6',
              image_css: 'max-w-19rem',
              image_url: 'on-us.png'
            }
          ]
        },
        {
          type: 'SUPPORTING ORGANIZATIONS',
          css_class: 'w-100 w-md-80 mx-auto justify-content-center',
          sponsor: [
            {
              css_class: 'col-12 col-md-4 mb-3',
              image_css: 'max-w-19rem',
              image_url: 'atlassian-community.png'
            },
            {
              css_class: 'col-12 col-md-4 mb-3',
              image_css: 'max-w-19rem',
              image_url: 'dala.png'
            },
            {
              css_class: 'col-12 col-md-4 mb-3',
              image_css: 'max-w-19rem',
              image_url: 'hkcs.png'
            },
            {
              css_class: 'col-12 col-md-4 mb-4',
              image_css: 'max-w-19rem',
              image_url: 'hkstp-saa.png'
            },
            {
              css_class: 'col-12 col-md-4 mb-3',
              image_css: 'max-w-19rem',
              image_url: 'ifta.png'
            },
            {
              css_class: 'col-12 col-md-4 mb-3',
              image_css: 'max-w-19rem',
              image_url: 'isoc.png'
            },
            {
              css_class: 'col-12 col-md-4 mb-3',
              image_css: 'max-w-19rem',
              image_url: 'openapihub.png'
            }
          ]
        }
      ]
    }
  },
  computed: {
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-inline-video-player').each(function() {
      new HSVideoPlayer($(this)).init()
    })
    // this.loadScriptRecapSubscribeForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "4daac438-a701-4a30-89de-fefdd7a7306f"
    //     });
    //   `
    //   this.$refs.recapSubscribeForm.appendChild(secondScript)
    // })
    // window.addEventListener('message', event => {
    //   if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
    //     this.isSubmitted = true
    //   }
    // })
    $('.js-fancybox').each(function() {
      $.HSCore.components.HSFancyBox.init($(this))
    })
  },
  methods: {
    // loadScriptRecapSubscribeForm(url, callback) {
    //   var script = document.createElement('script')
    //   script.type = 'text/javascript'
    //   script.src = url
    //   script.onreadystatechange = callback
    //   script.onload = callback
    //   this.$refs.recapSubscribeForm.appendChild(script)
    // }
    clearForm() {
      this.$refs.mcEmbeddedSubscribeForm.reset()
    }
  },
  metaInfo() {
    return {
      title: 'Apidays Connect Hong Kong 2023',
      titleTemplate: 'Apidays Connect Hong Kong 2023',
      link: [
        { rel: 'canonical', href: 'https://www.apidays.hk/' }
      ],
      meta: [
        { name: 'description', content: 'Apidays Connect Hong Kong 2023 — The API Landscape of Hong Kong' },
        { property: 'og:site_name', content: 'Apidays Connect Hong Kong 2023' },
        { property: 'og:title', content: 'Apidays Connect Hong Kong 2023' },
        { property: 'og:description', content: '30-31 Aug, 2023 - The API Landscape of Hong Kong | apidays Connect Hong Kong 2023 is going to bring together leading API experts both local and international to share their seasonal insights and lessons learnt in driving the team and business under the “new normal”.' },
        { property: 'og:image', content: 'https://www.apidays.hk/assets/img/thumbnails/apidays-hongkong-2023.jpg' },
        { property: 'og:url', content: 'https://www.apidays.hk/' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
</style>

